import { gql, useMutation } from '@apollo/client'
import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import * as yup from 'yup'
import { SiteHeader } from '../../components/compounds/header'
import { InfoAlert } from '../../components/elements/alerts/info'
import { PageTitle } from '../../components/elements/title'
import {
  CreateAccountMutation,
  CreateAccountMutationVariables,
} from '../../generated/types'
import { VALIDATION_EMAIL, VALIDATION_TERMS_AGREE } from '../start'
import {
  Field,
  FieldCheckbox,
  FieldErrorMessage,
  FieldHeaded,
  FieldLabel,
  FieldLabelSize,
  FieldRow,
  FormHeader,
  FormSection,
  FormSectionSeparator,
} from '../start/multipartform'

interface Values {
  email: string
  password: string
  termsAgree: boolean
}

const VALIDATION_SCHEMA = yup.object().shape({
  email: VALIDATION_EMAIL,
  password: yup.string().required().min(8).label('Password'),
  termsAgree: VALIDATION_TERMS_AGREE,
})

const CREATE_ACCOUNT_MUTATION = gql`
  mutation CreateAccount($input: CreateAccountInput!) {
    createAccount(input: $input) {
      __typename
    }
  }
`

export const Signup = () => {
  const [alertMessage, setAlertMessage] = useState<string | null>(null)
  const [createAccount] = useMutation<
    CreateAccountMutation,
    CreateAccountMutationVariables
  >(CREATE_ACCOUNT_MUTATION)

  const onSubmit = async (input: Values) => {
    const res = await createAccount({
      variables: { input: { email: input.email, password: input.password } },
    })

    if (res.errors) {
      throw new Error(`Could not update order: ${String(res.errors[0])}`)
    }

    if (!res.data) {
      throw new Error('Expected updateOrder data')
    }

    if (res.data.createAccount.__typename === 'AccountExistsError') {
      setAlertMessage(
        'An account with this email address already exists. You may try to sign into your account.',
      )
      return
    }

    console.log('created')
  }
  return (
    <>
      {alertMessage && (
        <InfoAlert
          title="Account creation failed"
          message={alertMessage}
          onConfirm={() => setAlertMessage(null)}
        />
      )}
      <SiteHeader funnel />
      <PageTitle>Create account - Recoreo</PageTitle>
      <div className="bg-c-secondary-bg min-h-screen">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 py-8 lg:py-16">
          <div className="pb-8 lg:pb-16">
            <h1 className="text-3xl leading-8 font-extrabold tracking-tight text-c-heading-text sm:text-4xl">
              Create your account
            </h1>
          </div>
          <Formik
            initialValues={{
              email: '',
              password: '',
              termsAgree: false,
            }}
            onSubmit={onSubmit}
            validationSchema={VALIDATION_SCHEMA}
          >
            {(props) => (
              <Form>
                <FormSection
                  first
                  header={
                    <FormHeader
                      heading={'Your account details'}
                      description={
                        'You will use this email address and password to sign into your Recoreo account.'
                      }
                    />
                  }
                >
                  <FieldRow>
                    <FieldLabel label="Email address">
                      <Field type="text" name="email" autoComplete="email" />
                    </FieldLabel>
                  </FieldRow>
                  <FieldErrorMessage name="email" />
                  <FieldRow>
                    <FieldLabel label="Password">
                      <Field type="password" name="password" />
                    </FieldLabel>
                  </FieldRow>
                  <FieldErrorMessage name="password" />
                </FormSection>
                <FormSectionSeparator />
                <FormSection
                  header={
                    <FormHeader heading="Terms and conditions" description="" />
                  }
                >
                  <FieldRow>
                    <FieldHeaded
                      size={FieldLabelSize.WIDE}
                      label="Terms and conditions"
                    >
                      <FieldCheckbox
                        type="checkbox"
                        name="termsAgree"
                        label={
                          <>
                            I agree to the{' '}
                            <a
                              onClick={(e) => e.stopPropagation()}
                              className="underline text-c-link-text hover:text-c-link-text-hover"
                              href="/legal/terms"
                              target="_blank"
                            >
                              terms and conditions
                            </a>{' '}
                            and{' '}
                            <a
                              onClick={(e) => e.stopPropagation()}
                              className="underline text-c-link-text hover:text-c-link-text-hover"
                              href="/legal/privacy-policy"
                              target="_blank"
                            >
                              privacy policy
                            </a>
                            .
                          </>
                        }
                      />
                    </FieldHeaded>
                  </FieldRow>
                  <FieldErrorMessage name="termsAgree" />

                  <button
                    type="submit"
                    className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-c-focus-ring bg-c-complement-bg hover:bg-c-complement-bg-hover `}
                  >
                    Create my account
                  </button>
                </FormSection>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}
