import { gql, useQuery } from '@apollo/client'
import React from 'react'
import { Helmet } from 'react-helmet'
import queryString from 'querystring'
import { Link, useLocation, useParams } from 'react-router-dom'
import { Footer } from '../../../components/compounds/footer'
import { SiteHeader } from '../../../components/compounds/header'
import { GetBlogPostBySlugQuery } from '../../../generated/types'
import {
  PostAuthorIcon,
  PostMeta,
  PostMetaDetails,
  PostMetaTime,
} from '../index/post'
import { Icon } from '../../../components/elements/icon'
import { Disqus } from './disqus'
import { parse } from 'date-fns'
import { SafeContent } from '../../../components/elements/safecontent'
import { PageTitle } from '../../../components/elements/title'
import { Error404 } from '../../error/404'

interface BlogSingleLoadedProps {
  singlePost: Extract<
    GetBlogPostBySlugQuery['blog']['singlePost'],
    { __typename: 'BlogPost' }
  >
}

interface BlogHeaderProps {
  h1: React.ReactNode
  hasFeaturedMedia: boolean
}

interface BlogMainLeftProps {
  featuredMediaImg: React.ReactNode
}

const BLOG_POST_FRAGMENT = gql`
  fragment BlogPostFragment on BlogPost {
    title
    featuredMedia {
      sourceUrl
      altText
      sizes {
        name
        width
        height
        sourceUrl
      }
    }
    author {
      name
      avatarUrls {
        size
        url
      }
    }
    safeContent
    link
    date

    headTags {
      type
      attributes {
        key
        value
      }
      content
    }
  }
`

const GET_BLOG_POST_BY_SLUG = gql`
  query GetBlogPostBySlug($slug: String!, $id: ID!, $queryRevision: Boolean!) {
    blog {
      id

      singlePost(slug: $slug) {
        __typename

        ... on BlogPost {
          ...BlogPostFragment
        }
      }

      latestSingleRevision(id: $id) @include(if: $queryRevision) {
        __typename

        ... on BlogPost {
          ...BlogPostFragment
        }
      }
    }
  }

  ${BLOG_POST_FRAGMENT}
`

const BlogHeader: React.FC<BlogHeaderProps> = ({ hasFeaturedMedia, h1 }) => (
  <header
    className={`pt-12 ${
      hasFeaturedMedia ? 'pb-40' : 'pb-12'
    } bg-c-aside-bg border-b-2 border-t-2 border-c-aside-separator`}
  >
    <div className="mx-auto px-4 lg:max-w-7xl sm:px-6">
      <div className="max-w-3xl">
        <div className="mb-6">
          <Link
            to="/blog/"
            className="bg-c-complement-bg text-white text-sm font-bold uppercase tracking-tight rounded px-3 py-2"
          >
            Blog
          </Link>
        </div>
        {h1}
      </div>
    </div>
  </header>
)

const BlogMain: React.FC = ({ children }) => (
  <div className="mx-auto lg:max-w-7xl flex flex-row">{children}</div>
)

const BlogMainLeft: React.FC<BlogMainLeftProps> = ({
  featuredMediaImg,
  children,
}) => (
  <div className="flex-none px-4 sm:px-6 w-full lg:w-auto">
    <div className={`${featuredMediaImg ? '-mt-32' : ''} w-full`}>
      <div className="lg:max-w-2xl xl:max-w-3xl">
        {/* Just to push out to max-width */}
        <div className="w-screen hidden lg:block" />
        {featuredMediaImg && (
          <div className="bg-c-loading-bg rounded-lg shadow-lg overflow-hidden">
            {featuredMediaImg}
          </div>
        )}
        <div className="mt-6 prose prose-lg md:prose-xl font-serif">
          {children}
        </div>
      </div>
    </div>
  </div>
)

const BlogSidebar: React.FC = ({ children }) => (
  <div className="flex-1 pr-4 sm:pr-6 hidden lg:block">
    <div className="-mt-32">{children}</div>
  </div>
)

const BlogSidebarSection: React.FC = ({ children }) => (
  <div className="pt-8">{children}</div>
)

const BlogLoadingPara = () => (
  <p className="bg-c-loading-bg">
    &nbsp;
    <br />
    &nbsp;
    <br />
    &nbsp;
    <br />
    &nbsp;
  </p>
)

const BlogLoadingSocial = () => (
  <div className="mt-2 bg-c-loading-bg text-white block p-4 flex space-x-3 rounded">
    &nbsp;
  </div>
)

const BlogSingleLoading = () => {
  return (
    <>
      <BlogHeader
        h1={
          <div className="bg-c-loading-dark-bg text-4xl leading-10 font-extrabold tracking-tight text-c-heading-text">
            &nbsp;
            <br />
            &nbsp;
            <span className="md:hidden">
              <br />
              &nbsp;
            </span>
          </div>
        }
        hasFeaturedMedia
      />
      <BlogMain>
        <BlogMainLeft
          featuredMediaImg={
            <div className="h-48 sm:h-72 md:h-96 lg:h-80 xl:h-96 w-full" />
          }
        >
          <BlogLoadingPara />
          <BlogLoadingPara />
          <BlogLoadingPara />
          <BlogLoadingPara />
          <BlogLoadingPara />
        </BlogMainLeft>
        <BlogSidebar>
          <BlogSidebarSection>
            <PostMeta>
              <PostAuthorIcon>
                <div className="text-c-loading-text">
                  <Icon type="user-circle" width={12} height={12} />
                </div>
              </PostAuthorIcon>
              <PostMetaDetails
                author={<div className="bg-c-loading-bg text-sm">&nbsp;</div>}
                time={
                  <div className="bg-c-loading-bg text-sm w-40">&nbsp;</div>
                }
              />
            </PostMeta>
          </BlogSidebarSection>
          <BlogSidebarSection>
            <h2 className="bg-c-loading-bg font-bold text-c-label-text">
              &nbsp;
            </h2>
            <BlogLoadingSocial />
            <BlogLoadingSocial />
            <BlogLoadingSocial />
            <BlogLoadingSocial />
          </BlogSidebarSection>
        </BlogSidebar>
      </BlogMain>
    </>
  )
}

const BlogSingleLoaded: React.FC<BlogSingleLoadedProps> = ({ singlePost }) => {
  const {
    title,
    featuredMedia,
    safeContent,
    author: { avatarUrls, name: authorName },
    link,
    date,
    headTags,
  } = singlePost

  const onSocialClick = (e: React.SyntheticEvent) => {
    e.preventDefault()
    const w = Number(e.currentTarget.getAttribute('data-win-width'))
    const h = Number(e.currentTarget.getAttribute('data-win-height'))
    const left = window.top.outerWidth / 2 + window.top.screenX - w / 2
    const top = window.top.outerHeight / 2 + window.top.screenY - h / 2

    var newWindow = window.open(
      e.currentTarget.getAttribute('href') as string,
      '',
      `scrollbars=no, width=${w}, height=${h}, top=${top}, left=${left}`,
    )
    newWindow?.focus()
  }

  const fullSize = featuredMedia?.sizes.find(({ name }) => name === 'full')

  return (
    <>
      <PageTitle>
        {headTags.find(({ type }) => type === 'title')?.content ?? title}
      </PageTitle>

      <Helmet>
        {headTags
          .filter(({ type }) => type !== 'title')
          .map(({ type, attributes, content }) => {
            const props = Object.fromEntries(
              attributes.map(({ key, value }) => [key, value]),
            )
            switch (type) {
              case 'meta':
                if (props.name === 'twitter:image') {
                  return (
                    <meta
                      {...props}
                      content={`https://recoreo.com/twitter-card?url=${encodeURIComponent(
                        props.content,
                      )}`}
                    />
                  )
                }

                return <meta {...props} />
              case 'link':
                return <link {...props} />
              case 'script':
                if (props.type === 'application/ld+json') {
                  console.log('test2')
                  return (
                    <script {...props}>
                      {content!.replace(
                        /(https?:\/\/[^\/]*?\/)blog\/(#|")/g,
                        '$1$2',
                      )}
                    </script>
                  )
                }

                return <script {...props}>{content}</script>
              default:
                throw new Error(`Tag type ${type} not supported`)
            }
          })}
      </Helmet>

      <main>
        <article>
          <BlogHeader
            h1={
              <h1 className="text-4xl leading-10 font-extrabold tracking-tight text-c-heading-text">
                {title}
              </h1>
            }
            hasFeaturedMedia={!!featuredMedia}
          />

          <BlogMain>
            <BlogMainLeft
              featuredMediaImg={
                featuredMedia && (
                  <img
                    width="768"
                    height={Math.floor(
                      ((fullSize?.height ?? 0) / (fullSize?.width ?? 0)) * 768,
                    )}
                    className="lg:h-80 xl:h-96 w-full object-cover"
                    src={featuredMedia.sourceUrl}
                    srcSet={featuredMedia.sizes
                      .map(
                        ({ width, height, sourceUrl }) =>
                          `${sourceUrl} ${width}w`,
                      )
                      .join(', ')}
                    sizes="(max-width: 1023px) 100vw, (min-width: 1024px) and (max-width: 1279px) 672px, 768px"
                    alt={featuredMedia.altText}
                  />
                )
              }
            >
              <SafeContent html={safeContent} />
            </BlogMainLeft>
            <BlogSidebar>
              <BlogSidebarSection>
                <PostMeta>
                  <PostAuthorIcon>
                    <img
                      className="h-12 w-12 rounded-full"
                      src={avatarUrls.find(({ size }) => size === 48)?.url}
                      srcSet={`${
                        avatarUrls.find(({ size }) => size === 96)?.url
                      } 2x`}
                      alt=""
                    />
                  </PostAuthorIcon>
                  <PostMetaDetails
                    author={
                      <p className="text-sm font-medium text-c-label-text">
                        {authorName}
                      </p>
                    }
                    time={
                      <PostMetaTime
                        date={parse(date, "yyyy-MM-dd'T'HH:mm:ss", new Date())}
                      />
                    }
                  />
                </PostMeta>
              </BlogSidebarSection>
              <BlogSidebarSection>
                <h2 className="font-bold text-c-label-text">
                  Share this post:
                </h2>
                <a
                  className="mt-2 bg-c-twitter text-white block p-4 flex space-x-3 rounded"
                  rel="nofollow noreferrer noopener"
                  href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                    link,
                  )}&text=${encodeURIComponent(title)}&amp;via=wearerecoreo`}
                  target="_blank"
                  onClick={onSocialClick}
                  data-win-width="600"
                  data-win-height="500"
                >
                  <Icon width={6} height={6} type="twitter" />
                  <span>Tweet</span>
                </a>
                <a
                  className="mt-2 bg-c-linkedin text-white block p-4 flex space-x-3 rounded"
                  rel="nofollow noreferrer noopener"
                  href={`https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
                    link,
                  )}&title=${encodeURIComponent(title)}`}
                  target="_blank"
                  onClick={onSocialClick}
                  data-win-width="500"
                  data-win-height="600"
                >
                  <Icon width={6} height={6} type="linkedin" />
                  <span>Share</span>
                </a>
                <a
                  className="mt-2 bg-c-facebook text-white block p-4 flex space-x-3 rounded"
                  rel="nofollow noreferrer noopener"
                  href={`https://www.facebook.com/sharer.php?u=${encodeURIComponent(
                    link,
                  )}&title=${encodeURIComponent(title)}`}
                  target="_blank"
                  onClick={onSocialClick}
                  data-win-width="600"
                  data-win-height="745"
                >
                  <Icon width={6} height={6} type="facebook" />
                  <span>Post</span>
                </a>
                <a
                  className="mt-2 bg-c-reddit text-white block p-4 flex space-x-3 rounded"
                  rel="nofollow noreferrer noopener"
                  href={`https://reddit.com/submit?url=${encodeURIComponent(
                    link,
                  )}&title=${encodeURIComponent(title)}`}
                  target="_blank"
                  onClick={onSocialClick}
                  data-win-width="600"
                  data-win-height="700"
                >
                  <Icon width={6} height={6} type="reddit" />
                  <span>Submit</span>
                </a>
              </BlogSidebarSection>
            </BlogSidebar>
          </BlogMain>
        </article>
        <div className="mt-12 py-12 lg:mt-16 lg:py-16 border-c-aside-separator bg-c-secondary-bg">
          <div className="px-4 sm:px-6 mx-auto lg:max-w-4xl">
            <Disqus />
          </div>
        </div>
      </main>
    </>
  )
}

export const BlogSingle = () => {
  const { slug } = useParams<{ slug?: string }>()
  const location = useLocation()
  const qs = queryString.parse(location.search.replace(/^\?/, ''))
  const previewId = slug ? qs.preview_id : qs.p

  const { loading, error, data } = useQuery<GetBlogPostBySlugQuery>(
    GET_BLOG_POST_BY_SLUG,
    {
      variables: {
        slug: slug || '',
        id: previewId ? Number(previewId) : 0,
        queryRevision: !!previewId,
      },
    },
  )

  if (error) {
    throw error
  }

  if (
    data?.blog.latestSingleRevision?.__typename === 'NotFoundError' ||
    data?.blog.singlePost.__typename === 'NotFoundError'
  ) {
    return <Error404 />
  }

  return (
    <>
      <SiteHeader />
      {loading && <BlogSingleLoading />}
      {data && (
        <BlogSingleLoaded
          singlePost={
            (data?.blog.latestSingleRevision || data?.blog.singlePost) as any
          }
        />
      )}
      <Footer />
    </>
  )
}
