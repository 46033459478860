import { Transition } from '@headlessui/react'
import React, { useEffect, useState } from 'react'
import { Icon } from '../icon'

interface Props {
  title: string
  message: string
  confirmText?: string
  onConfirm: Function
}

export const InfoAlert: React.FC<Props> = ({
  title,
  message,
  confirmText = 'Okay',
  onConfirm,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [leaving, setLeaving] = useState(false)

  useEffect(() => {
    setIsOpen(true)
  }, [])

  useEffect(() => {
    // Hack to wait for the animation
    if (leaving) {
      setTimeout(() => {
        onConfirm()
      }, 300)
    }
  }, [leaving])

  const onClick = () => {
    setLeaving(true)
  }

  return (
    <div
      className="fixed z-50 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition
          show={isOpen && !leaving}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="fixed inset-0 bg-c-background-bg bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
        </Transition>
        {/* This element is to trick the browser into centering the modal contents. */}
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div
          className={`ease-out ${
            isOpen && !leaving
              ? 'opacity-100 translate-y-0 sm:scale-100'
              : 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          } duration-300 inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm w-full sm:p-6`}
        >
          <div>
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-c-icon-danger-bg text-c-icon-danger-text">
              <Icon type="exclamation-circle" width={6} height={6} />
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <h3
                className="text-lg leading-6 font-medium text-c-heading-text"
                id="modal-title"
              >
                {title}
              </h3>
              <div className="mt-2">
                <p className="text-sm text-c-primary-text">{message}</p>
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-6">
            <button
              disabled={leaving ? true : undefined}
              onClick={onClick}
              type="button"
              className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-c-complement-bg text-base font-medium text-white hover:bg-c-complement-bg-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-c-focus-ring sm:text-sm"
            >
              {confirmText}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
