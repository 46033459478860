import React from 'react'
import { HelpLink } from '../../components/elements/alerts/helplink'
import { TurnaroundGuarantee } from '../../components/elements/turnaround'

export const HomeLongSell = () => (
  <div className="py-16 bg-white overflow-hidden lg:py-24">
    <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
      <svg
        className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
        width="404"
        height="784"
        fill="none"
        viewBox="0 0 404 784"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
            x="0"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <rect
              x="0"
              y="0"
              width="4"
              height="4"
              className="text-c-texture-bg"
              fill="currentColor"
            />
          </pattern>
        </defs>
        <rect
          width="404"
          height="784"
          fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
        />
      </svg>

      <div className="relative lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
        <div className="relative">
          <h2 className="text-2xl font-extrabold text-c-heading-text tracking-tight sm:text-3xl">
            Gym Geek
          </h2>
          <p className="mt-3 text-lg text-c-primary-text">
          <a className="font-bold underline text-c-link-text hover:text-c-link-text-hover" href="https://gymgeek.com/">Gym Geek</a> is a trusted health and fitness site. Launched in 2014, Gym Geek has served millions of visitors across the United States, United Kingdom and countries around the world!</p>
          <p className="mt-3 text-lg text-c-primary-text">
Passionate about promoting health and fitness, Gym Geek provides well researched guides and articles that help people achieve their fitness goals more effectively.</p>
        </div>

        <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
          <svg
            className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
            width="784"
            height="404"
            fill="none"
            viewBox="0 0 784 404"
          >
            <defs>
              <pattern
                id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x="0"
                  y="0"
                  width="4"
                  height="4"
                  className="text-c-texture-bg"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width="784"
              height="404"
              fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
            />
          </svg>
          <img
            className="relative mx-auto shadow-2xl"
            width="490"
            src="/img/gym-geek-490.png"
            alt=""
          />
        </div>
      </div>

      
      <svg
        className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
        width="404"
        height="784"
        fill="none"
        viewBox="0 0 404 784"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
            x="0"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <rect
              x="0"
              y="0"
              width="4"
              height="4"
              className="text-c-texture-bg"
              fill="currentColor"
            />
          </pattern>
        </defs>
        <rect
          width="404"
          height="784"
          fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
        />
      </svg> 

      <div className="relative mt-12 sm:mt-16 lg:mt-24">
        <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="lg:col-start-2">
            <h2 className="text-2xl font-extrabold text-c-heading-text tracking-tight sm:text-3xl">
              Sweet Pricing
            </h2>
            <p className="mt-3 text-lg text-c-primary-text">
              <a className="font-bold underline text-c-link-text hover:text-c-link-text-hover" href="https://sweetpricing.com/">Sweet Pricing</a> is an innovative dynamic pricing tool for mobile games and apps. Sweet Pricing created one of the first dynamic pricing SDKs for in-app purchases, serving over 80 mobile apps on Google Play and the App Store.
            </p>
            <p className="mt-3 text-lg text-c-primary-text">
              Sweet Pricing stopped providing its API in 2018.
            </p>

          </div>

          <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
            <svg
              className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
              width="784"
              height="404"
              fill="none"
              viewBox="0 0 784 404"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-c-texture-bg"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width="784"
                height="404"
                fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)"
              />
            </svg>
            <img
            className="relative mx-auto shadow-2xl"
            width="490"
            src="/img/sweet-pricing-490.png"
            alt=""
          />
          </div>
        </div>
      </div>
    </div>
  </div>
)
