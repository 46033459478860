import { gql, useQuery } from '@apollo/client'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router'
import { Footer } from '../../../components/compounds/footer'
import { SiteHeader } from '../../../components/compounds/header'
import { PageTitle } from '../../../components/elements/title'
import { GetBlogPostsQuery } from '../../../generated/types'
import { BlogPost, BlogPostLoading, BLOGPOST_BLOGPOST_FRAGMENT } from './post'
import queryString from 'querystring'
import { Pages } from './pages'
import { Redirect } from 'react-router-dom'

interface BlogIndexLoadedProps {
  page: number
  data: GetBlogPostsQuery
}

const PER_PAGE = 6

const GET_BLOG_POSTS = gql`
  query GetBlogPosts($perPage: Int!, $page: Int!) {
    blog {
      id

      posts(perPage: $perPage, page: $page) {
        totalPages
        blogPosts {
          ...BlogPost_blogPost
        }
      }
    }
  }

  ${BLOGPOST_BLOGPOST_FRAGMENT}
`

const BlogIndexLoading = () => (
  <>
    <BlogPostLoading />
    <BlogPostLoading />
    <BlogPostLoading />
  </>
)

const BlogIndexLoaded: React.FC<BlogIndexLoadedProps> = ({
  page,
  data: {
    blog: {
      posts: { totalPages, blogPosts },
    },
  },
}) => (
  <>
    {blogPosts.map((post, i) => (
      <BlogPost key={i} post={post} />
    ))}
    <Pages page={page} totalPages={totalPages} />
  </>
)

export const BlogIndex = () => {
  const location = useLocation()
  const qs = queryString.parse(location.search.replace(/^\?/, ''))
  const pageStr = qs.page
  let page: number

  if (typeof pageStr === 'undefined') {
    page = 1
  } else if (typeof pageStr === 'string' && Number.isInteger(Number(pageStr))) {
    page = Number(pageStr)
  } else {
    throw new Error('page is invalid')
  }

  const { loading, error, data } = useQuery<GetBlogPostsQuery>(GET_BLOG_POSTS, {
    variables: {
      perPage: PER_PAGE,
      page,
    },
  })

  if (error) {
    throw error
  }

  if (data?.blog.posts.blogPosts.length === 0) {
    return <Redirect to="/blog/" />
  }

  return (
    <div>
      <SiteHeader />
      <PageTitle>Recoreo Blog</PageTitle>
      <div className="relative bg-gray-50 pb-20 mt-12 px-4 sm:px-6 lg:pb-28 lg:px-8">
        <div className="relative max-w-3xl mx-auto">
          <div className="max-w-lg mx-auto lg:max-w-none">
            {loading && <BlogIndexLoading />}
            {data && <BlogIndexLoaded page={page} data={data} />}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
