import React from 'react'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Logo } from '../../components/compounds/hero/logo'
import { Icon } from '../../components/elements/icon'
import { PageTitle } from '../../components/elements/title'
import { ResponseContext } from '../../contexts/response'

export const Error404 = () => {
  const response = useContext(ResponseContext)

  response.setStatusCode(404)

  return (
    <>
      <PageTitle>404 Not Found</PageTitle>
      <div className="relative bg-white overflow-hidden sm:mt-12 lg:mt-24">
        <div className="relative pt-6 pb-6 sm:pb-6">
          <div className="max-w-2xl mx-auto px-4 sm:px-6">
            <Link to="/" className="inline-block">
              <Logo />
            </Link>
            <h1 className="mt-10 text-4xl tracking-tight font-extrabold text-c-heading-text sm:leading-none lg:text-5xl xl:text-6xl">
              404 - Page Not Found
            </h1>
            <p className="mt-6 text-c-primary-text">
              Sorry, the page your requested could not be found.
            </p>
            <p className="mt-6 text-c-primary-text">
              <Link
                className="text-lg lg:text-4xl text-c-link-text hover:text-c-link-text-hover hover:underline inline-block"
                to="/"
              >
                <div className="flex">
                  <span className="mr-2 lg:mr-4">Go to homepage</span>
                  <span className="flex items-center lg:hidden">
                    <Icon type="arrow-right" width={6} height={6} />
                  </span>
                  <span className="flex items-center hidden lg:block">
                    <Icon type="arrow-right" width={12} height={12} />
                  </span>
                </div>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  )
}
