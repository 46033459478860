import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Waypoint } from 'react-waypoint'

let globalDisqusScriptLoaded = false

const ensureGlobalDisqusScript = () => {
  if (globalDisqusScriptLoaded) {
    return
  }

  globalDisqusScriptLoaded = true

  const d = document
  const s = d.createElement('script')
  s.src = 'https://recoreo.disqus.com/embed.js'
  s.setAttribute('data-timestamp', String(+new Date()))
  ;(d.head || d.body).appendChild(s)
}

const ensureDisqus = (url: string, id: string) => {
  const w = window as any
  w.disqus_config = function () {
    this.page.identifier = id
    this.page.url = url
  }

  ensureGlobalDisqusScript()

  if (w.DISQUS && w.DISQUS.reset) {
    w.DISQUS.reset({
      reload: true,
      config: w.disqus_config,
    })
  }
}

export const Disqus = () => {
  const { pathname } = useLocation()
  const { slug } = useParams<{ slug: string }>()
  const [loaded, setLoaded] = useState(false)

  const onWaypointEnter = () => {
    if (!loaded) {
      setLoaded(true)
      ensureDisqus(`https://${window.location.hostname}${pathname}`, slug)
    }
  }

  return (
    <>
      <Waypoint onEnter={onWaypointEnter} bottomOffset={'-200px'} />
      <div id="disqus_thread"></div>
    </>
  )
}
