import React, { useState } from 'react'
import { HelpAlert } from './help'

interface Props {
  title: string
  content: React.ReactElement
  children: ({ onClick }: { onClick: () => void }) => React.ReactElement
}

export const HelpLink: React.FC<Props> = ({ title, content, children }) => {
  const [show, setShow] = useState(false)

  const onClick = () => setShow(true)
  const onConfirm = () => setShow(false)

  return (
    <>
      {show && (
        <HelpAlert title={title} content={content} onConfirm={onConfirm} />
      )}
      {children({ onClick })}
    </>
  )
}
