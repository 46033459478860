import { gql, useMutation, useQuery } from '@apollo/client'
import { Stripe, StripeElement, StripeElements } from '@stripe/stripe-js'
import { ErrorMessage, Form, Formik, useFormikContext } from 'formik'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import * as yup from 'yup'
import Measure from 'react-measure'
import { FunnelFooter } from '../../components/compounds/footer'
import { SiteHeader } from '../../components/compounds/header'
import { InfoAlert } from '../../components/elements/alerts/info'
import { Icon, SmallIcon } from '../../components/elements/icon'
import { PageTitle } from '../../components/elements/title'
import { StripeContext } from '../../contexts/stripe'
import {
  GetOrderQuery,
  UpdateOrderMutation,
  UpdateOrderMutationVariables,
} from '../../generated/types'
import {
  Field,
  FieldCheckbox,
  FieldErrorMessage,
  FieldHeaded,
  FieldLabel,
  FieldLabelSize,
  FieldRow,
  FormHeader,
  FormSection,
  FormSectionSeparator,
} from './multipartform'
import {
  GoogleLinesProvider,
  GooglePreview,
  TRIAL_PREFIX,
} from './googlepreview'
import {
  COUNTRIES,
  CountrySelectField,
} from '../../components/elements/countryselectfield'
import { checkVAT, countries } from 'jsvat'
import { TurnaroundGuarantee } from '../../components/elements/turnaround'
import { PromotionsContext } from '../../contexts/promotions'
import { UserTypeAlert } from '../../components/elements/alerts/usertype'
import { AppStateContext } from '../../contexts/appstate'
import { UserTypeContext } from '../../contexts/usertype'
import { MoneyBackGuarantee } from '../../components/elements/moneyback'

export interface Values {
  email: string
  blogPostTitle: string
  blogPostDescription: string
  invoiceName: string
  invoiceAddressLine1: string
  invoiceAddressLine2: string
  invoiceCity: string
  invoiceState: string
  invoicePostalCode: string
  invoiceCountry: string
  invoiceVatNumber: string
  totalBeforeVatAndDiscountsPence: number
  totalDiscountsPence: number
  totalBeforeVatPence: number
  totalVatPence: number
  totalCostPence: number
  termsAgree: boolean
}

interface StartPageLoadedProps {
  order: GetOrderQuery['order']
  onComplete: () => void
}

type Await<T> = T extends PromiseLike<infer U> ? U : T

const SIGN_UP_MARKETING_MUTATION = gql`
  mutation SignUpMarketing($email: String!) {
    signUpMarketing(email: $email)
  }
`

const ORDER_FRAGMENT = gql`
  fragment orderFragment on Order {
    id
    email
    paymentIntent {
      clientSecret
      status
    }
  }
`

const GET_ORDER = gql`
  query GetOrder {
    order {
      ...orderFragment
    }
  }

  ${ORDER_FRAGMENT}
`

export const VALIDATION_EMAIL = yup
  .string()
  .email('You must provide a valid email address')
  .required()
  .max(255)
  .label('Email')
export const VALIDATION_TERMS_AGREE = yup
  .boolean()
  .equals(
    [true],
    'You must agree to the terms and conditions before continuing.',
  )
  .required()
const VALIDATION_SCHEMA = yup.object().shape({
  email: VALIDATION_EMAIL,
  blogPostTitle: yup.string().required().max(150).label('Blog post title'),
  blogPostDescription: yup
    .string()
    .required()
    .max(300)
    .label('Blog post description'),
  invoiceName: yup
    .string()
    .required()
    .max(35)
    .label('Company or business name'),
  invoiceAddressLine1: yup.string().required().max(35).label('Address line 1'),
  invoiceAddressLine2: yup.string().max(35).label('Address line 2'),
  invoiceCity: yup.string().required().max(35).label('City, district or town'),
  invoiceState: yup
    .string()
    .required()
    .max(35)
    .label('State, county or province'),
  invoicePostalCode: yup
    .string()
    .required()
    .max(35)
    .label('ZIP or postal code'),
  invoiceCountry: yup
    .string()
    .required()
    .oneOf(COUNTRIES.map(({ code }) => code))
    .label('Country'),
  invoiceVatNumber: yup
    .string()
    .test('is-valid-vat', 'EU/UK VAT number is invalid', function (value) {
      if (
        !COUNTRIES.find(({ code }) => code === this.parent.invoiceCountry)
          ?.collectEuVatNumber
      ) {
        return true
      }

      if (!value?.trim()) {
        return true
      }

      const res = checkVAT(value, countries)

      // Do not allow GB VAT numbers to be entered, since it has no effect on what VAT we charge.
      return res.isValid && res.country?.isoCode.short !== 'GB'
    }),
  termsAgree: VALIDATION_TERMS_AGREE,
  cardNumber: yup.boolean().equals([true]).required(),
  cardExpiry: yup.boolean().equals([true]).required(),
  cardCvc: yup.boolean().equals([true]).required(),
})

const UPDATE_ORDER_MUTATION = gql`
  mutation UpdateOrder($input: OrderInput!) {
    updateOrder(input: $input) {
      __typename

      ... on Order {
        ...orderFragment
      }
    }
  }

  ${ORDER_FRAGMENT}
`

const formatAmount = (amount: number) => {
  return <>£{(amount / 100).toFixed(2)}</>
}

const CalculateCost = () => {
  const { values, setFieldValue } = useFormikContext<Values>()
  const { promotions } = useContext(PromotionsContext)

  useEffect(() => {
    const collectEuVatNumber =
      COUNTRIES.find(({ code }) => code === values.invoiceCountry)
        ?.collectEuVatNumber ?? false
    const invoiceVatNumber = values.invoiceVatNumber
    const canExcludeVat = Boolean(collectEuVatNumber && invoiceVatNumber.trim())
    const totalBeforeVatAndDiscounts = 7000
    const totalDiscountsPence = promotions.some(({ id }) => id === '30OFF')
      ? 3000
      : 0
    const totalBeforeVat = totalBeforeVatAndDiscounts - totalDiscountsPence
    const totalVat = canExcludeVat ? 0 : totalBeforeVat * 0.2
    const totalCostPence = totalBeforeVat + totalVat

    setFieldValue('totalBeforeVatAndDiscountsPence', totalBeforeVatAndDiscounts)
    setFieldValue('totalDiscountsPence', totalDiscountsPence)
    setFieldValue('totalBeforeVatPence', totalBeforeVat)
    setFieldValue('totalVatPence', totalVat)
    setFieldValue('totalCostPence', totalCostPence)
  }, [values.invoiceCountry, values.invoiceVatNumber, promotions])

  return null
}

const TrackInputConversion = () => {
  const {
    values: { blogPostTitle, blogPostDescription },
  } = useFormikContext<Values>()
  const [sent, setSent] = useState(false)

  useEffect(() => {
    if (!sent && blogPostTitle.trim() && blogPostDescription.trim()) {
      window.dataLayer.push({
        event: 'recoreo.journey.addedContentSpecification',
      })
      setSent(true)
    }
  }, [blogPostTitle, blogPostDescription])

  return null
}

// const AutoSave = () => {
//   const { values } = useFormikContext<Values>()
//   const [updateOrderEmail, { loading, called, error }] = useMutation(
//     UPDATE_ORDER_EMAIL_MUTATION,
//   )
//   const [pendingEmail, setPendingEmail] = useState<string | null>(null)

//   useEffect(() => {
//     if (VALIDATION_EMAIL.isValidSync(values.email)) {
//       setPendingEmail(values.email)
//     }
//   }, [values.email])

//   useEffect(() => {
//     if (pendingEmail && !loading) {
//       updateOrderEmail({
//         variables: {
//           email: values.email,
//         },
//       })
//       setPendingEmail(null)
//     }
//   }, [pendingEmail, loading])

//   return null
// }

interface StripeFieldsProps {
  elementsRef: React.MutableRefObject<StripeElements | null>
}

interface StripeFieldProps {
  focused: boolean
}

const StripeField = React.forwardRef<HTMLDivElement, StripeFieldProps>(
  ({ focused }, ref) => (
    <div
      className={`-m-px p-px ${
        focused ? 'bg-c-focus-border' : 'bg-transparent'
      } rounded-md`}
    >
      <div
        className={`py-2 px-3 shadow-sm sm:text-sm border ${
          focused ? 'border-c-focus-border' : 'border-c-input-border'
        } rounded-md bg-white`}
        ref={ref}
      />
    </div>
  ),
)

const TextProgress: React.FC<
  Omit<React.ComponentProps<typeof GoogleLinesProvider>, 'children'>
> = (props) => {
  return (
    <GoogleLinesProvider {...props}>
      {({ isError, isWarning, progress }) => (
        <div className="mt-1 w-full h-2 border border-c-input-border bg-c-secondary-bg rounded-md overflow-hidden">
          <div
            className={`h-2 ${
              isError
                ? 'bg-c-progress-error'
                : isWarning
                ? 'bg-c-progress-warning'
                : 'bg-c-progress-success'
            }`}
            style={{
              width: `${progress * 100}%`,
            }}
          />
        </div>
      )}
    </GoogleLinesProvider>
  )
}

const StripeFields: React.FC<StripeFieldsProps> = ({ elementsRef }) => {
  const { loadStripe } = useContext(StripeContext)
  const stripeElementCardNumberRef = useRef<HTMLDivElement>(null)
  const [cardNumberFocused, setCardNumberFocused] = useState(false)
  const stripeElementCardExpiryRef = useRef<HTMLDivElement>(null)
  const [cardExpiryFocused, setCardExpiryFocused] = useState(false)
  const stripeElementCardCvcRef = useRef<HTMLDivElement>(null)
  const [cardCvcFocused, setCardCvcFocused] = useState(false)
  const [cardNumberMessage, setCardNumberMessage] = useState<string>(
    'You must enter a credit card number',
  )
  const [cardExpiryMessage, setCardExpiryMessage] = useState<string>(
    'You must enter an expiry date',
  )
  const [cardCvcMessage, setCardCvcMessage] = useState<string>(
    'You must enter a card CVC',
  )
  const formik = useFormikContext()

  useEffect(() => {
    const fn = async () => {
      const stripe = await loadStripe()

      if (!stripe) {
        throw new Error('Expected Stripe to be loaded')
      }

      const elements = stripe.elements()

      elementsRef.current = elements

      const cardNumber = elements.create('cardNumber', {
        showIcon: true,
        style: {
          base: {
            lineHeight: '20px',
          },
        },
      })
      cardNumber.on('focus', () => setCardNumberFocused(true))
      cardNumber.on('blur', () => {
        formik.setFieldTouched('cardNumber')
        setCardNumberFocused(false)
      })
      cardNumber.on('change', (event) => {
        setCardNumberMessage(
          event.error?.message ?? 'You must enter a credit card number',
        )
        formik.setFieldValue('cardNumber', !event.error && !event.empty)
      })
      const cardExpiry = elements.create('cardExpiry', {
        style: {
          base: {
            lineHeight: '20px',
          },
        },
      })
      cardExpiry.on('focus', () => setCardExpiryFocused(true))
      cardExpiry.on('blur', () => {
        formik.setFieldTouched('cardExpiry')
        setCardExpiryFocused(false)
      })
      cardExpiry.on('change', (event) => {
        setCardExpiryMessage(
          event.error?.message ?? 'You must enter an expiry date',
        )
        formik.setFieldValue('cardExpiry', !event.error && !event.empty)
      })
      const cardCvc = elements.create('cardCvc', {
        style: {
          base: {
            lineHeight: '20px',
          },
        },
      })
      cardCvc.on('focus', () => setCardCvcFocused(true))
      cardCvc.on('blur', () => {
        formik.setFieldTouched('cardCvc')
        setCardCvcFocused(false)
      })
      cardCvc.on('change', (event) => {
        setCardCvcMessage(event.error?.message ?? 'You must enter a card CVC')
        formik.setFieldValue('cardCvc', !event.error && !event.empty)
      })

      if (!stripeElementCardNumberRef.current) {
        throw new Error('unexpected stripeElementCardNumberRef')
      }
      if (!stripeElementCardExpiryRef.current) {
        throw new Error('unexpected stripeElementCardExpiryRef')
      }
      if (!stripeElementCardCvcRef.current) {
        throw new Error('unexpected stripeElementCardExpiryRef')
      }

      cardNumber.mount(stripeElementCardNumberRef.current)
      cardExpiry.mount(stripeElementCardExpiryRef.current)
      cardCvc.mount(stripeElementCardCvcRef.current)
    }

    fn()
  }, [])

  return (
    <FormSection
      header={<FormHeader heading="Payment method" description="" />}
    >
      <FieldRow>
        <FieldLabel label="Card number">
          <StripeField
            focused={cardNumberFocused}
            ref={stripeElementCardNumberRef}
          />
        </FieldLabel>
      </FieldRow>
      <FieldErrorMessage name="cardNumber" message={cardNumberMessage} />
      <FieldRow>
        <FieldLabel size={FieldLabelSize.SMALL} label="Expiry date">
          <StripeField
            focused={cardExpiryFocused}
            ref={stripeElementCardExpiryRef}
          />
        </FieldLabel>
        <FieldLabel size={FieldLabelSize.SMALL} label="Card CVC">
          <StripeField focused={cardCvcFocused} ref={stripeElementCardCvcRef} />
        </FieldLabel>
      </FieldRow>
      <FieldErrorMessage name="cardExpiry" message={cardExpiryMessage} />
      <FieldErrorMessage name="cardCvc" message={cardCvcMessage} />
    </FormSection>
  )
}

const StartPageLoaded: React.FC<StartPageLoadedProps> = ({
  order,
  onComplete,
}) => {
  const { loadStripe } = useContext(StripeContext)
  const { userType, setUserType } = useContext(UserTypeContext)
  const elementsRef = useRef<StripeElements | null>(null)
  const [alertMessage, setAlertMessage] = useState<string | null>(null)
  const [updateOrder] = useMutation<
    UpdateOrderMutation,
    UpdateOrderMutationVariables
  >(UPDATE_ORDER_MUTATION)

  const onSubmit = async (input: Values) => {
    const updateRes = await updateOrder({
      variables: {
        input: {
          email: input.email,
          blogPostTitle: input.blogPostTitle,
          blogPostDescription: input.blogPostDescription,
          invoiceName: input.invoiceName,
          invoiceAddressLine1: input.invoiceAddressLine1,
          invoiceAddressLine2: input.invoiceAddressLine2,
          invoiceCity: input.invoiceCity,
          invoiceState: input.invoiceState,
          invoicePostalCode: input.invoicePostalCode,
          invoiceCountry: input.invoiceCountry,
          invoiceVatNumber: input.invoiceVatNumber,
          totalCostPence: input.totalCostPence,
        },
      },
    })

    if (updateRes.errors) {
      throw new Error(`Could not update order: ${String(updateRes.errors[0])}`)
    }

    if (!updateRes.data) {
      throw new Error('Expected updateOrder data')
    }

    const order = updateRes.data.updateOrder

    if (order.__typename === 'AccountExistsError') {
      throw new Error('updateOrder not supported, AccountExistsError')
    }

    if (!order.paymentIntent) {
      throw new Error(
        'Expected paymentIntent.clientSecret before submitting form',
      )
    }

    const clientSecret = order.paymentIntent.clientSecret
    const stripe = await loadStripe()

    if (!stripe) {
      throw new Error('Expected Stripe to be loaded')
    }
    if (!elementsRef.current) {
      throw new Error('Expected elementsRef.current')
    }

    const card = elementsRef.current.getElement('cardNumber')

    if (!card) {
      throw new Error('expected card element')
    }

    const res = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card,
      },
    })

    if (res.error) {
      setAlertMessage(res.error.message || 'Unknown payment error')
      return
    }

    onComplete()
  }

  if (
    order.paymentIntent &&
    order.paymentIntent.status !== 'REQUIRES_PAYMENT_METHOD'
  ) {
    return <Redirect to="/thank-you" />
  }

  return (
    <>
      {userType === null && <UserTypeAlert onConfirm={setUserType} />}
      {alertMessage && (
        <InfoAlert
          title="Your order failed"
          message={alertMessage}
          onConfirm={() => setAlertMessage(null)}
        />
      )}
      <SiteHeader funnel />
      <PageTitle>Start your order - Recoreo</PageTitle>
      <div className="bg-c-secondary-bg">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 py-8 lg:py-16">
          <div className="pb-8 lg:pb-16">
            <h1 className="text-3xl leading-8 font-extrabold tracking-tight text-c-heading-text sm:text-4xl">
              Start your order
            </h1>
            <div className="max-w-prose">
              <p className="mt-4 text-base text-c-secondary-primary-text">
                We aim to deliver your content within 1 working day. If we miss
                this date, we’ll automatically add credit to your account under
                our <TurnaroundGuarantee />.
              </p>
              <p className="mt-4 text-base text-c-secondary-primary-text">
                <MoneyBackGuarantee />
              </p>
            </div>
          </div>

          <Formik
            initialValues={{
              email: order.email ?? '',
              blogPostTitle: '',
              blogPostDescription: '',
              cardNumber: false,
              cardExpiry: false,
              cardCvc: false,
              invoiceName: '',
              invoiceAddressLine1: '',
              invoiceAddressLine2: '',
              invoiceCity: '',
              invoiceState: '',
              invoicePostalCode: '',
              invoiceCountry: '',
              invoiceVatNumber: '',
              totalBeforeVatAndDiscountsPence: 0,
              totalDiscountsPence: 0,
              totalBeforeVatPence: 0,
              totalVatPence: 0,
              totalCostPence: 0,
              termsAgree: false,
            }}
            onSubmit={onSubmit}
            validationSchema={VALIDATION_SCHEMA}
          >
            {(props) => (
              <Form>
                <CalculateCost />
                <TrackInputConversion />
                <FormSection
                  first
                  header={
                    <FormHeader
                      heading={/*'Your recoreo.com account'*/ 'Email address'}
                      description={
                        /*"Sign in to your account, or continue as a guest."*/ 'Your blog post will be delivered by email.'
                      }
                    />
                  }
                >
                  <FieldRow>
                    <FieldLabel label="Email address">
                      <Field type="text" name="email" autoComplete="email" />
                    </FieldLabel>
                  </FieldRow>
                  <FieldErrorMessage name="email" />
                </FormSection>
                <FormSectionSeparator />
                <FormSection
                  header={
                    <FormHeader
                      heading="Content specification"
                      description="Tell us about your blog post."
                    />
                  }
                >
                  <FieldRow>
                    <FieldLabel
                      label="Blog post title"
                      size={FieldLabelSize.WIDE}
                      after={
                        <TextProgress
                          name="blogPostTitle"
                          containerWidth={600}
                          lastLineErrorPx={600}
                          warningProgress={1 - 1 / Math.E}
                          fontSize={20}
                        />
                      }
                    >
                      <Field type="text" name="blogPostTitle" />
                    </FieldLabel>
                  </FieldRow>
                  <FieldErrorMessage name="blogPostTitle" />
                  <FieldRow>
                    <FieldLabel
                      label="Blog post description"
                      size={FieldLabelSize.WIDE}
                      after={
                        <TextProgress
                          name="blogPostDescription"
                          containerWidth={600}
                          lastLineErrorPx={425}
                          warningProgress={1 - 1 / (2 * Math.E)}
                          maxLines={2}
                          fontSize={14}
                          prefix={TRIAL_PREFIX}
                        />
                      }
                    >
                      <Field
                        as="textarea"
                        rows={3}
                        name="blogPostDescription"
                      />
                    </FieldLabel>
                  </FieldRow>
                  <FieldErrorMessage name="blogPostDescription" />
                  <FieldRow>
                    <div className="col-span-6 sm:col-span-6">
                      <GooglePreview />
                    </div>
                  </FieldRow>
                </FormSection>
                <FormSectionSeparator />
                <FormSection
                  header={
                    <FormHeader heading="Invoice details" description="" />
                  }
                >
                  <FieldRow>
                    <FieldLabel label="Company or business name">
                      <Field type="text" name="invoiceName" />
                    </FieldLabel>
                  </FieldRow>
                  <FieldErrorMessage name="invoiceName" />
                  <FieldRow>
                    <FieldLabel label="Address line 1">
                      <Field type="text" name="invoiceAddressLine1" />
                    </FieldLabel>
                  </FieldRow>
                  <FieldErrorMessage name="invoiceAddressLine1" />
                  <FieldRow>
                    <FieldLabel label="Address line 2 (optional)">
                      <Field type="text" name="invoiceAddressLine2" />
                    </FieldLabel>
                  </FieldRow>
                  <FieldErrorMessage name="invoiceAddressLine2" />
                  <FieldRow>
                    <FieldLabel label="City, district or town">
                      <Field type="text" name="invoiceCity" />
                    </FieldLabel>
                  </FieldRow>
                  <FieldErrorMessage name="invoiceCity" />
                  <FieldRow>
                    <FieldLabel label="State, county or province">
                      <Field type="text" name="invoiceState" />
                    </FieldLabel>
                  </FieldRow>
                  <FieldErrorMessage name="invoiceState" />
                  <FieldRow>
                    <FieldLabel label="ZIP or postal code">
                      <Field type="text" name="invoicePostalCode" />
                    </FieldLabel>
                  </FieldRow>
                  <FieldErrorMessage name="invoicePostalCode" />
                  <FieldRow>
                    <FieldLabel label="Country">
                      <CountrySelectField name="invoiceCountry" />
                    </FieldLabel>
                  </FieldRow>
                  <FieldErrorMessage name="invoiceCountry" />
                  {COUNTRIES.find(
                    ({ code }) => code === props.values.invoiceCountry,
                  )?.collectEuVatNumber && (
                    <FieldRow>
                      <FieldLabel label="VAT number (optional)">
                        <Field type="text" name="invoiceVatNumber" />
                      </FieldLabel>
                    </FieldRow>
                  )}
                  <FieldErrorMessage name="invoiceVatNumber" />
                </FormSection>
                <FormSectionSeparator />
                <StripeFields elementsRef={elementsRef} />
                <FormSectionSeparator />

                <FormSection
                  header={<FormHeader heading="Order summary" description="" />}
                >
                  <div className="space-y-3 text-sm">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="col-span-8 sm:col-span-4">
                        1,000+ word blog post
                      </div>
                      <div className="col-span-4 sm:col-span-2 text-right font-mono">
                        {formatAmount(
                          props.values.totalBeforeVatAndDiscountsPence,
                        )}
                      </div>
                    </div>
                    <div className="grid grid-cols-12 gap-6">
                      <div className="col-span-8 sm:col-span-4 text-right pt-3 border-t border-transparent">
                        Subtotal
                      </div>
                      <div className="col-span-4 sm:col-span-2 text-right font-mono pt-3 border-t border-c-input-border">
                        {formatAmount(
                          props.values.totalBeforeVatAndDiscountsPence,
                        )}
                      </div>
                    </div>
                    {props.values.totalDiscountsPence !== 0 && (
                      <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-8 sm:col-span-4 text-right">
                          Discount 30OFF applied
                        </div>
                        <div className="col-span-4 sm:col-span-2 text-right font-mono">
                          -{formatAmount(props.values.totalDiscountsPence)}
                        </div>
                      </div>
                    )}
                    <div className="grid grid-cols-12 gap-6">
                      <div className="col-span-8 sm:col-span-4 text-right">
                        VAT
                      </div>
                      <div className="col-span-4 sm:col-span-2 text-right font-mono">
                        {props.values.totalVatPence
                          ? formatAmount(props.values.totalVatPence)
                          : 'N/A'}
                      </div>
                    </div>
                    <div className="grid grid-cols-12 gap-6">
                      <div className="col-span-8 sm:col-span-4 text-right font-bold">
                        Total
                      </div>
                      <div className="col-span-4 sm:col-span-2 text-right font-mono font-bold">
                        {formatAmount(props.values.totalCostPence)}
                      </div>
                    </div>
                  </div>

                  <div>
                    <p className="text-sm text-c-secondary-primary-text">
                      We will place a hold for{' '}
                      {formatAmount(props.values.totalCostPence)} on your card
                      immediately and charge your card once we accept your
                      order.
                    </p>
                  </div>

                  <FieldRow>
                    <FieldHeaded
                      size={FieldLabelSize.WIDE}
                      label="Terms and conditions"
                    >
                      <FieldCheckbox
                        type="checkbox"
                        name="termsAgree"
                        label={
                          <>
                            I agree to the{' '}
                            <a
                              onClick={(e) => e.stopPropagation()}
                              className="underline text-c-link-text hover:text-c-link-text-hover"
                              href="/legal/terms"
                              target="_blank"
                            >
                              terms and conditions
                            </a>{' '}
                            and{' '}
                            <a
                              onClick={(e) => e.stopPropagation()}
                              className="underline text-c-link-text hover:text-c-link-text-hover"
                              href="/legal/privacy-policy"
                              target="_blank"
                            >
                              privacy policy
                            </a>
                            .
                          </>
                        }
                      />
                    </FieldHeaded>
                  </FieldRow>
                  <FieldErrorMessage name="termsAgree" />

                  <button
                    type="submit"
                    className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-c-focus-ring bg-c-complement-bg hover:bg-c-complement-bg-hover `}
                  >
                    Submit Order
                  </button>
                </FormSection>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <FunnelFooter />
    </>
  )
}

export const StartPage = () => {
  const { loading, error, data, refetch } = useQuery<GetOrderQuery>(GET_ORDER, {
    notifyOnNetworkStatusChange: true,
  })

  if (error) {
    throw error
  }

  if (loading) {
    return <div>loading</div>
  }

  if (!data) {
    throw new Error('expected data')
  }

  return <StartPageLoaded order={data.order} onComplete={refetch} />
}
