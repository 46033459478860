import { Transition } from '@headlessui/react'
import React, { useEffect, useState } from 'react'
import { Icon } from '../icon'

export type UserType = 'freelancer' | 'owner'

interface Props {
  onConfirm: (type: UserType) => void
}

export const UserTypeAlert: React.FC<Props> = ({ onConfirm }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [type, setType] = useState<UserType>('freelancer')
  const [leaving, setLeaving] = useState(false)
  const [rand] = useState(Math.random())

  useEffect(() => {
    setIsOpen(true)
  }, [])

  useEffect(() => {
    // Hack to wait for the animation
    if (leaving) {
      setTimeout(() => {
        onConfirm(type)
      }, 300)
    }
  }, [leaving])

  const onClick = (type: UserType) => {
    setType(type)
    setLeaving(true)
  }

  const buttonsArr = [
    <button
      type="button"
      className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-c-secondary-bg text-base font-medium text-c-secondary-primary-text hover:bg-c-secondary-bg-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-c-focus-ring sm:w-auto sm:text-sm"
      onClick={() => onClick('freelancer')}
    >
      freelance writer
    </button>,
    <button
      type="button"
      className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-c-secondary-bg text-base font-medium text-c-secondary-primary-text hover:bg-c-secondary-bg-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-c-focus-ring sm:w-auto sm:text-sm"
      onClick={() => onClick('owner')}
    >
      business or website owner
    </button>,
  ]

  const buttons =
    rand < 0.5 ? (
      <>
        {buttonsArr[0]}
        <div className="block mt-3 sm:inline-block sm:mt-0 sm:ml-3">
          {buttonsArr[1]}
        </div>
      </>
    ) : (
      <>
        {buttonsArr[1]}
        <div className="block mt-3 sm:inline-block sm:mt-0 sm:ml-3">
          {buttonsArr[0]}
        </div>
      </>
    )

  return (
    <div
      className="fixed z-50 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition
          show={isOpen && !leaving}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="fixed inset-0 bg-c-background-bg bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
        </Transition>
        {/* This element is to trick the browser into centering the modal contents. */}
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div
          className={`ease-out ${
            isOpen && !leaving
              ? 'opacity-100 translate-y-0 sm:scale-100'
              : 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          } duration-300 inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full sm:p-6`}
        >
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-c-icon-notice-bg text-c-icon-notice-text sm:mx-0 sm:h-10 sm:w-10">
              <Icon type="cursor-click" width={6} height={6} />
            </div>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3
                className="text-lg leading-6 font-medium text-c-heading-text"
                id="modal-title"
              >
                Which of the following best describe you?
              </h3>
              <div className="mt-2">
                <p className="text-sm text-c-primary-text">I am a...</p>
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex">{buttons}</div>
        </div>
      </div>
    </div>
  )
}
