import React from 'react'
import { Icon } from '../../components/elements/icon'

const FeatureOriginal = () => (
  <div>
    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-c-complement-bg text-white">
      <Icon type="star" width={6} height={6} />
    </div>
    <div className="mt-5">
      <dt className="text-lg leading-6 font-medium text-c-label-text">
        100% original content
      </dt>
      <dd className="mt-2 text-base text-c-primary-text">
        Content we deliver is 100% original. You can be confident that our
        articles do not exist on other websites, since we write every article
        from scratch.
      </dd>
    </div>
  </div>
)

const FeatureProfessional = () => (
  <div>
    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-c-complement-bg text-white">
      <svg
        className="h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
        />
      </svg>
    </div>
    <div className="mt-5">
      <dt className="text-lg leading-6 font-medium text-c-label-text">
        Professional writing and research
      </dt>
      <dd className="mt-2 text-base text-c-primary-text">
        We will match your order to one of our highly-skilled content writers.
        No hand-holding here &mdash; we'll work to your brief and do the
        research ourselves.
      </dd>
    </div>
  </div>
)

const FeatureSeo = () => (
  <div>
    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-c-complement-bg text-white">
      <svg
        className="w-6 h-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M3 4h13M3 8h9m-9 4h6m4 0l4-4m0 0l4 4m-4-4v12"
        />
      </svg>
    </div>
    <div className="mt-5">
      <dt className="text-lg leading-6 font-medium text-c-label-text">
        A focus on SEO
      </dt>
      <dd className="mt-2 text-base text-c-primary-text">
        We always start with Google. Tell us how your blog post should appear in
        search, and which keyword you would like to rank for, and we'll do the
        rest.
      </dd>
    </div>
  </div>
)

const FeatureSeoContent = () => (
  <div>
    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-c-complement-bg text-white">
      <svg
        className="w-6 h-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M3 4h13M3 8h9m-9 4h6m4 0l4-4m0 0l4 4m-4-4v12"
        />
      </svg>
    </div>
    <div className="mt-5">
      <dt className="text-lg leading-6 font-medium text-c-label-text">
        SEO-optimized content for websites
      </dt>
      <dd className="mt-2 text-base text-c-primary-text">
        Our content is always SEO-friendly. Adding high-quality, well-structured
        content to your site can help your website rank high in Google.
      </dd>
    </div>
  </div>
)

const FeatureQualityControl = () => (
  <div>
    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-c-complement-bg text-white">
      <svg
        className="w-6 h-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z"
        />
      </svg>
    </div>
    <div className="mt-5">
      <dt className="text-lg leading-6 font-medium text-c-label-text">
        Automated quality control
      </dt>
      <dd className="mt-2 text-base text-c-primary-text">
        Text analysis and artificial intelligence (AI) tools grade every blog
        post we write. So you get top-quality content every time.
      </dd>
    </div>
  </div>
)

const FeatureUnbeatableValue = () => (
  <div>
    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-c-complement-bg text-white">
      <Icon type="currency-pound" width={6} height={6} />
    </div>
    <div className="mt-5">
      <dt className="text-lg leading-6 font-medium text-c-label-text">
        Unbeatable value
      </dt>
      <dd className="mt-2 text-base text-c-primary-text">
        We only work with highly-skilled, UK-based content writers. Nowhere else
        will you find such affordable yet high-quality content for your website.
      </dd>
    </div>
  </div>
)

const FeatureVetted = () => (
  <div>
    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-c-complement-bg text-white">
      <Icon type="check" width={6} height={6} />
    </div>
    <div className="mt-5">
      <dt className="text-lg leading-6 font-medium text-c-label-text">
        Vetted content writers
      </dt>
      <dd className="mt-2 text-base text-c-primary-text">
        We only work with highly-skilled, UK-based content writers. Unlike other
        platforms, we vet our writers and use artificial intelligence to ensure
        high quality.
      </dd>
    </div>
  </div>
)

const FeatureLondon = () => (
  <div>
    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-c-complement-bg text-white">
      <Icon type="underground" width={6} height={6} />
    </div>
    <div className="mt-5">
      <dt className="text-lg leading-6 font-medium text-c-label-text">
        London SEO content specialists
      </dt>
      <dd className="mt-2 text-base text-c-primary-text">
        We are a local London-based business and we only work with UK-based
        content writers. As SEO content specialists, we know how important great
        content is.
      </dd>
    </div>
  </div>
)

export const HomeFeatures = () => (
  <div className="pt-12 pb-24 bg-white">
    <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
      <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8">
        <FeatureProfessional />
        <FeatureSeo />
        <FeatureQualityControl />
      </dl>
    </div>
  </div>
)

export const HomeFeaturesContentForWebsite = () => (
  <div className="pt-12 pb-24 bg-white">
    <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
      <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8">
        <FeatureOriginal />
        <FeatureSeoContent />
        <FeatureUnbeatableValue />
      </dl>
    </div>
  </div>
)

export const HomeFeaturesSeoContentWriters = () => (
  <div className="pt-12 pb-24 bg-white">
    <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
      <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8">
        <FeatureSeoContent />
        <FeatureVetted />
        <FeatureLondon />
      </dl>
    </div>
  </div>
)
