import React from 'react'
import { Icon } from '../../elements/icon'
import { Link as RouterLink } from 'react-router-dom'

const ColumnHeading = ({ children }: { children: React.ReactNode }) => (
  <h3 className="text-sm font-semibold text-c-inverse-label-text tracking-wider uppercase">
    {children}
  </h3>
)

const ColumnList = ({ children }: { children: React.ReactNode }) => (
  <ul className="mt-4 space-y-4">{children}</ul>
)

const Link = ({
  href,
  children,
}: {
  children: React.ReactNode
  href: string
}) => (
  <RouterLink
    to={href}
    className="text-base text-c-inverse-nav-text hover:text-c-inverse-nav-text-hover"
  >
    {children}
  </RouterLink>
)

export const FunnelFooter = function () {
  return (
    <footer className="bg-c-inverse-bg" aria-labelledby="footerHeading">
      <h2 id="footerHeading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <p className="mt-8 text-base text-c-inverse-primary-text md:mt-0 md:order-1">
          <a
            href="/legal/terms"
            target="_blank"
            className="text-base text-c-inverse-nav-text hover:text-c-inverse-nav-text-hover"
          >
            Terms and Conditions
          </a>{' '}
          &bull;{' '}
          <a
            href="/legal/privacy-policy"
            target="_blank"
            className="text-base text-c-inverse-nav-text hover:text-c-inverse-nav-text-hover"
          >
            Privacy Policy
          </a>
        </p>
        <p className="mt-3 text-base text-c-inverse-primary-text md:order-1">
          &copy; 2023 Recoreo Ltd. Recoreo Ltd is a limited company registered
          in England and Wales, with company register number 10005255 and
          registered office address Recoreo Ltd, 20-22 Wenlock Road, London,
          England, N1 7GU.
        </p>
      </div>
    </footer>
  )
}

export const Footer = function () {
  return (
    <footer className="bg-c-inverse-bg" aria-labelledby="footerHeading">
      <h2 id="footerHeading" className="sr-only">
        Footer
      </h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="md:grid md:grid-cols-6 md:gap-8">
          <div>
            <ColumnHeading>Recoreo</ColumnHeading>
            <ColumnList>
              <li>
                <Link href="/">Home</Link>
              </li>
            </ColumnList>
          </div>
          <div className="mt-12 md:mt-0">
            <ColumnHeading>Resources</ColumnHeading>
            <ColumnList>
              <li>
                <Link href="/blog/">Blog</Link>
              </li>
            </ColumnList>
          </div>
          <div className="mt-12 md:mt-0">
            <ColumnHeading>Legal</ColumnHeading>
            <ColumnList>
              <li>
                <Link href="/legal/terms">Terms and Conditions</Link>
              </li>
              <li>
                <Link href="/legal/privacy-policy">Privacy Policy</Link>
              </li>
            </ColumnList>
          </div>
        </div>
        <div className="mt-8 xl:mt-0"></div>
        <div className="mt-8 border-t border-c-inverse-separator pt-8 md:flex md:items-center md:justify-between">
          <div className="flex space-x-6 md:order-2">
            <a
              href="https://www.facebook.com/recoreoltd/"
              className="text-c-inverse-nav-text hover:text-c-inverse-nav-text-hover"
              target="_blank"
              rel="noreferrer noopener"
            >
              <span className="sr-only">Facebook</span>
              <Icon width={6} height={6} type="facebook" />
            </a>

            <a
              href="https://twitter.com/wearerecoreo"
              className="text-c-inverse-nav-text hover:text-c-inverse-nav-text-hover"
              target="_blank"
              rel="noreferrer noopener"
            >
              <span className="sr-only">Twitter</span>
              <Icon width={6} height={6} type="twitter" />
            </a>
          </div>
          <p className="mt-8 text-base text-c-inverse-primary-text md:mt-0 md:order-1">
            &copy; 2023 Recoreo Ltd. Recoreo Ltd is a limited company registered
            in England and Wales, with company register number 10005255 and
            registered office address Recoreo Ltd, 20-22 Wenlock Road, London,
            England, N1 7GU.
          </p>
        </div>
      </div>
    </footer>
  )
}
