import { useEffect, useState } from 'react'

let _hydrating = true

export const useHydrating = () => {
  const [hydrating, setHydrating] = useState(_hydrating)

  useEffect(() => {
    _hydrating = false
    setHydrating(false)
  }, [])

  return hydrating
}
