import { createContext } from 'react'

interface Promotion {
  id: string
}

interface PromotionsContext {
  promotions: Promotion[]
}

export const PromotionsContext = createContext<PromotionsContext>({
  promotions: [],
})
