import React, { useContext } from 'react'

import { BackgroundTextureInset } from './backgroundtextureinset'
import { Nav } from './nav'
import {
  useDeliveryDay,
  DELIVERY_WORKING_DAYS,
} from '../../../hooks/deliveryday'
import { Link } from 'react-router-dom'
import { Hero30Off } from './hero30off'
import { PromotionsContext } from '../../../contexts/promotions'
import { SmallIcon } from '../../elements/icon'
import { MoneyBackGuarantee } from '../../elements/moneyback'

interface Props {
  orderNow?: (day: string) => React.ReactElement
}

export const Hero: React.FC<Props> = ({
  orderNow = (day) => (
    <>
      Order today and get your content{' '}
      {day === 'tomorrow' ? (
        <strong className="underline">tomorrow</strong>
      ) : (
        day
      )}
    </>
  ),
  children,
}) => {
  const deliveryDay = useDeliveryDay()
  const { promotions } = useContext(PromotionsContext)

  return (
    <div className="relative bg-white overflow-hidden">
      <BackgroundTextureInset />
      <div className="relative pt-6 pb-16 sm:pb-24">
        <Nav />
        {promotions.some(({ id }) => id === '30OFF') && <Hero30Off />}

        <main className="mt-16 sm:mt-24">
          <div className="mx-auto max-w-7xl">
            <div className="lg:grid lg:grid-cols-12 lg:gap-8">
              <div className="px-4 sm:px-6 sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left lg:flex lg:items-center">
                <div>{children}</div>
              </div>
              <div className="mt-16 sm:mt-24 lg:mt-0 lg:col-span-6">
                <div className="bg-c-capture-bg sm:max-w-md sm:w-full sm:mx-auto sm:rounded-lg sm:overflow-hidden">
                  <div className="px-4 py-8 sm:px-10">
                    <div>
                      <h2 className="text-xl sm:text-2xl font-extrabold text-c-heading-text">
                        Need help?
                      </h2>
                    </div>

                    <div className="mt-6">
                      Email <a className="font-bold underline text-c-link-text hover:text-c-link-text-hover"  href="mailto:support@recoreo.com">support@recoreo.com</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
