import React, { useState } from 'react'
import { TurnaroundGuarantee } from '../../components/elements/turnaround'

interface FaqQuestion {
  question: React.ReactNode
  answer: React.ReactNode
}

const FAQ_QUESTIONS: FaqQuestion[] = [
  {
    question: <>Can I choose a content writer or freelancer?</>,
    answer: (
      <>
        Our model makes content creation and delivery as simple as possible. We
        match your order to one of our highly-skilled content writers, so you
        don’t have to vet and manage freelancers directly.
      </>
    ),
  },
  {
    question: <>How does Recoreo ensure blog posts are high quality?</>,
    answer: (
      <>
        People and process ensure top-quality blog posts every time. We assess
        every content writer we work with and use automated quality control
        tools to measure and maintain our high standards.
      </>
    ),
  },
  {
    question: <>What is automated quality control?</>,
    answer: (
      <>
        We use both text analysis and artificial intelligence (AI) tools to
        measure the quality of our content. These tools consider spelling and
        grammar, text structure and predicted SEO impact. Content that falls
        short of the required grade threshold is rejected and reworked before
        delivery.
      </>
    ),
  },
  {
    question: <>Do you offer consultations or free revisions?</>,
    answer: (
      <>
        To maintain our simple proposition and low costs, we aim to deliver
        top-quality content every time. We do not routinely offer free
        revisions. But if you are unhappy with our work, please get in touch and
        we’ll try to put things right.
      </>
    ),
  },
  {
    question: <>Do you guarantee that content will rank in Google?</>,
    answer: (
      <>
        There are many factors that influence whether a page will rank highly on
        a search engine results page. For this reason, we do not offer
        guarantees for pages ranking in search.
      </>
    ),
  },
  {
    question: <>How long will it take for content to be delivered?</>,
    answer: (
      <>
        We aim to deliver our orders in 1 working day (London business days). In
        the rare event that your content is delayed, we’ll automatically add
        credit to your account under our <TurnaroundGuarantee />.
      </>
    ),
  },
]

const FaqQuestion: React.FC<{ faqQuestion: FaqQuestion }> = ({
  faqQuestion: { question, answer },
}) => {
  const [isOpen, setIsOption] = useState(false)

  const toggleIsOption = () => {
    setIsOption(!isOpen)
  }

  return (
    <div className="pt-6">
      <dt className="text-lg">
        <button
          className="text-left w-full flex justify-between items-start text-c-label-text"
          onClick={toggleIsOption}
        >
          <span className="font-medium text-c-label-text">{question}</span>
          <span className="ml-6 h-7 flex items-center">
            <svg
              className={`${
                isOpen ? '-rotate-180' : 'rotate-0'
              } h-6 w-6 transform`}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </span>
        </button>
      </dt>
      <dd className={`mt-2 pr-12 ${isOpen ? '' : 'hidden'}`}>
        <p className="text-base text-c-primary-text">{answer}</p>
      </dd>
    </div>
  )
}

export const HomeFaq = () => (
  <div className="bg-aside-bg">
    <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto divide-y-2 divide-c-aside-separator">
        <h2 className="text-center text-3xl font-extrabold text-c-heading-text sm:text-4xl">
          Frequently asked questions
        </h2>
        <dl className="mt-6 space-y-6 divide-y divide-c-aside-separator">
          {FAQ_QUESTIONS.map((faqQuestion, i) => (
            <FaqQuestion faqQuestion={faqQuestion} key={i} />
          ))}
        </dl>
      </div>
    </div>
  </div>
)
