import React from 'react'

import { HomeHero } from './hero'
import { HomeFeatures } from './features'
import { HomeLongSell } from './longsell'
import { HomeCta } from './cta'
import { HomeFaq } from './faq'
import { Footer } from '../../components/compounds/footer'
import { PageTitle } from '../../components/elements/title'
import { Helmet } from 'react-helmet'

export const Home = () => (
  <>
    <PageTitle>Recoreo - Real-world Impact</PageTitle>
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@graph': [
            {
              '@type': 'WebPage',
              '@id': 'https://recoreo.com/',
              url: 'https://recoreo.com/',
              name: 'Recoreo',
              isPartOf: {
                '@id': 'https://recoreo.com/#website',
              },
              about: {
                '@id': 'https://recoreo.com/#organization',
              },
              description:
                "Recoreo is an operator of online brands that have real-world impact on people's health, happiness or financial wellbeing.",
              breadcrumb: {
                '@id': 'https://recoreo.com/#breadcrumb',
              },
              publisher: {
                '@id': 'https://recoreo.com/#organization',
              },
              inLanguage: 'en-US',
            },
            {
              '@type': 'BreadcrumbList',
              '@id': 'https://recoreo.com/#breadcrumb',
              itemListElement: [
                {
                  '@type': 'ListItem',
                  position: 1,
                  name: 'Recoreo',
                },
              ],
            },
            {
              '@type': 'WebSite',
              '@id': 'https://recoreo.com/#website',
              url: 'https://recoreo.com/',
              name: 'Recoreo',
              description:
                "Recoreo is an operator of online brands that have real-world impact on people's health, happiness or financial wellbeing.",
              publisher: {
                '@id': 'https://recoreo.com/#organization',
              },
              inLanguage: 'en-US',
            },
            {
              '@type': 'Organization',
              '@id': 'https://recoreo.com/#organization',
              name: 'Recoreo',
              legalName: 'Recoreo Ltd',
              url: 'https://recoreo.com/',
              sameAs: [
                'https://find-and-update.company-information.service.gov.uk/company/10005255',
                'https://www.facebook.com/recoreoltd/',
                'https://twitter.com/wearerecoreo',
                'https://www.linkedin.com/company/recoreo/',
              ],
              logo: {
                '@type': 'ImageObject',
                inLanguage: 'en-US',
                '@id': 'https://recoreo.com/#/schema/logo/image/',
                url: 'https://recoreo.com/android-chrome-256x256.png',
                contentUrl: 'https://recoreo.com/android-chrome-256x256.png',
                width: 256,
                height: 256,
                caption: 'Recoreo',
              },
              image: {
                '@id': 'https://recoreo.com/#/schema/logo/image/',
              },
            },
          ],
        })}
      </script>
    </Helmet>
    <HomeHero>
      <h1 className="mt-4 text-4xl tracking-tight font-bold text-c-heading-light-text sm:mt-5 sm:leading-none lg:mt-6 lg:text-5xl xl:text-6xl">
        <span className="md:block">
          Real-world{' '}
          <strong className="font-extrabold text-c-heading-text">impact</strong>
        </span>
      </h1>
      <p className="mt-3 text-base text-c-primary-text sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
        Recoreo is an operator of online brands that have real-world impact on
        people's health, happiness or financial wellbeing.
      </p>
    </HomeHero>
    <HomeLongSell />
    {/* <HomeFaq /> */}
    <Footer />
  </>
)
