import React from 'react'
import { Footer } from '../../../components/compounds/footer'
import { PageTitle } from '../../../components/elements/title'
import { HomeCta } from '../cta'
import { HomeFaq } from '../faq'
import { HomeFeaturesContentForWebsite } from '../features'
import { HomeHero } from '../hero'
import { HomeLongSellContentForWebsite } from '../longsellcontentforwebsite'

export const ContentForWebsite = () => (
  <>
    <PageTitle>
      Recoreo – Content for Website – 100% Original Content From £70 + VAT
    </PageTitle>
    <HomeHero
      orderNow={(day) => (
        <>
          Order today and get{' '}
          <strong className="underline">content for your website</strong> {day}!
        </>
      )}
    >
      <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-c-heading-text sm:mt-5 sm:leading-none lg:mt-6 lg:text-5xl xl:text-6xl">
        <span className="md:block">
          Buy content for your website. £70 + VAT.
        </span>
      </h1>
      <p className="mt-3 text-base text-c-primary-text sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
        Recoreo is the simple way to buy content for your website or blog. Give
        us a topic and we'll write 1,000 words for just £70 + VAT.
      </p>
    </HomeHero>
    <HomeFeaturesContentForWebsite />
    <HomeLongSellContentForWebsite />
    <HomeCta />
    <HomeFaq />
    <Footer />
  </>
)
