import { createContext } from 'react'
import { UserType } from '../components/elements/alerts/usertype'

interface AppStateContext {
  serverDate: string
  env: string
  initialUserType: UserType | null
}

export const AppStateContext = createContext<AppStateContext>({
  serverDate: '',
  env: '',
  initialUserType: null,
})
