import React from 'react'
import { HelpLink } from './alerts/helplink'

export const TurnaroundGuarantee = () => (
  <HelpLink
    title="What is the Turnaround Guarantee?"
    content={
      <>
        <p className="text-sm text-c-primary-text">
          We aim to deliver your content within 1 working day. If we miss this
          date, we’ll automatically add credit to your account under our
          Turnaround Guarantee.
        </p>

        <p className="mt-3 text-sm text-c-primary-text">
          For a £70 blog post, our guarantee credit rate is £30. That means
          you’ll receive a £30 credit if your content is delivered in 2 or more
          working days.
        </p>

        <p className="mt-3 text-sm text-c-primary-text">
          Credit expires 12 months from issuance. Non-transferrable. No exchange
          for cash. Exclusions apply.{' '}
          <a
            className="underline text-c-link-text hover:text-c-link-text-hover"
            href="/legal/terms"
            target="_blank"
          >
            Terms and conditions
          </a>{' '}
          apply.
        </p>
      </>
    }
  >
    {({ onClick }) => (
      <button
        className="underline text-c-link-text hover:text-c-link-text-hover"
        onClick={onClick}
      >
        Turnaround Guarantee
      </button>
    )}
  </HelpLink>
)
