import { createContext } from 'react'
import { UserType } from '../components/elements/alerts/usertype'

interface UserTypeContext {
  userType: UserType | null
  setUserType: (type: UserType) => void
}

export const UserTypeContext = createContext<UserTypeContext>({
  userType: null,
  setUserType: () => {},
})
