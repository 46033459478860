import React from 'react'
import { Footer } from '../../../components/compounds/footer'
import { PageTitle } from '../../../components/elements/title'
import { HomeCta } from '../cta'
import { HomeFaq } from '../faq'
import { HomeFeaturesSeoContentWriters } from '../features'
import { HomeHero } from '../hero'
import { HomeLongSellSeoContentWriters } from '../longsellseocontentwriters'

export const SeoContentWriters = () => (
  <>
    <PageTitle>
      Recoreo - Hire SEO Content Writers - Content Specialists
    </PageTitle>
    <HomeHero
      orderNow={(day) => (
        <>
          Hire an <strong className="underline">SEO content writer</strong>{' '}
          today and get your content{' '}
          {day === 'tomorrow' ? (
            <strong className="underline">tomorrow</strong>
          ) : (
            day
          )}
          !
        </>
      )}
    >
      <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-c-heading-text sm:mt-5 sm:leading-none lg:mt-6 lg:text-5xl xl:text-6xl">
        <span className="md:block">Hire SEO Content Writers. £70 + VAT.</span>
      </h1>
      <p className="mt-3 text-base text-c-primary-text sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
        Get SEO-friendly content from Recoreo's high-quality content writers.
        Give us a topic and we'll write 1,000 words for just £70 + VAT.
      </p>
    </HomeHero>
    <HomeFeaturesSeoContentWriters />
    <HomeLongSellSeoContentWriters />
    <HomeCta />
    <HomeFaq />
    <Footer />
  </>
)
