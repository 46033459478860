import React, { useState } from 'react'
import { Transition } from '@headlessui/react'

import { Logo } from './logo'
import { Link } from 'react-router-dom'

interface Props {
  funnel?: boolean
}

interface LinkDef {
  label: string
  href: string
}

const LINKS: LinkDef[] = [
  {
    label: 'Blog',
    href: '/blog/',
  },
]

export const Nav: React.FC<Props> = ({ funnel = false }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <nav
        className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6"
        aria-label="Global"
      >
        <div className="flex items-center flex-1">
          <div className="flex items-center justify-between w-full md:w-auto">
            <Link to="/">
              <span className="sr-only">Recoreo Home Page</span>
              <Logo />
            </Link>
            {!funnel && (
              <div className="-mr-2 flex items-center md:hidden">
                <button
                  type="button"
                  className="bg-c-secondary-bg rounded-md p-2 inline-flex items-center justify-center text-c-nav-text-dark hover:bg-c-secondary-bg-hover"
                  aria-expanded={isOpen ? 'true' : 'false'}
                  onClick={() => setIsOpen(true)}
                >
                  <span className="sr-only">Open main menu</span>
                  {/* Heroicon name: outline/menu */}
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                </button>
              </div>
            )}
          </div>
          {!funnel && (
            <div className="hidden space-x-10 md:flex md:ml-10">
              {LINKS.map(({ href, label }, i) => (
                <Link
                  key={i}
                  to={href}
                  className="font-medium text-c-nav-text hover:text-c-nav-text-hover"
                >
                  {label}
                </Link>
              ))}
            </div>
          )}
        </div>
        {!funnel && (
          <div className="hidden md:flex">
            <a
              href="/"
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-c-nav-text-dark bg-c-secondary-bg hover:bg-c-secondary-bg-hover hover:text-c-nav-text-dark-hover"
            >
              Sign in
            </a>
          </div>
        )}
      </nav>
      <Transition
        show={isOpen && !funnel}
        enter="duration-150 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
          <div className="rounded-lg shadow-md bg-white ring-2 ring-black ring-opacity-5 overflow-hidden">
            <div className="px-5 pt-4 flex items-center justify-between">
              <div>
                <Link to="/">
                  <span className="sr-only">Recoreo Home Page</span>
                  <Logo />
                </Link>
              </div>
              <div className="-mr-2">
                <button
                  type="button"
                  className="rounded-md p-2 inline-flex items-center justify-center text-c-nav-text-dark bg-c-secondary-bg hover:bg-c-secondary-bg-hover"
                  onClick={() => setIsOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  {/* Heroicon name: outline/x */}
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="px-2 pt-2 pb-3 space-y-1">
              {LINKS.map(({ href, label }, i) => (
                <Link
                  key={i}
                  to={href}
                  className="block px-3 py-2 rounded-md text-base font-medium text-c-label-text hover:text-c-nav-text-dark-hover"
                >
                  {label}
                </Link>
              ))}
            </div>
            <a
              href="/"
              className="block w-full px-5 py-3 text-center font-medium text-c-nav-text-dark bg-c-secondary-bg hover:bg-c-secondary-bg-hover hover:text-c-nav-text-dark-hover"
            >
              Log in
            </a>
          </div>
        </div>
      </Transition>
    </>
  )
}
