import React from 'react'
import { HelpLink } from './alerts/helplink'
import { SmallIcon } from './icon'

interface Props {
  textClassName?: string
}

export const MoneyBackGuarantee: React.FC<Props> = ({
  textClassName = 'text-c-link-text hover:text-c-link-text-hover',
}) => (
  <HelpLink
    title="What is the money-back guarantee?"
    content={
      <>
        <p className="text-sm text-c-primary-text">
          If you are unhappy with your content for any reason, you can claim a
          full refund within 30 days.
        </p>

        <p className="mt-3 text-sm text-c-primary-text">
          To claim a refund, email{' '}
          <a href="mailto:support@recoreo.com">support@recoreo.com</a>. If you
          have already published the content on a website or elsewhere, you are
          not eligible for a refund.
        </p>

        <p className="mt-3 text-sm text-c-primary-text">
          Once a refund is requested and approved, all Intellectual Property
          Rights are assigned back to Recoreo Ltd. We reserve the right to sell
          the content and assign the intellectual property rights to others.
        </p>

        <p className="mt-3 text-sm text-c-primary-text">
          <a
            className="underline text-c-link-text hover:text-c-link-text-hover"
            href="/legal/terms"
            target="_blank"
          >
            Terms and conditions
          </a>{' '}
          apply.
        </p>
      </>
    }
  >
    {({ onClick }) => (
      <button className={textClassName} onClick={onClick}>
        <div className="flex">
          <div className="flex items-center">
            <SmallIcon width={5} height={5} type="shield-check" />
          </div>{' '}
          <div className="ml-2 font-bold underline">
            100% money-back guarantee
          </div>
        </div>
      </button>
    )}
  </HelpLink>
)
