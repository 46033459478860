import React from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { Icon } from '../../../components/elements/icon'

interface Props {
  page: number
  totalPages: number
}

export const Pages: React.FC<Props> = ({ page, totalPages }) => {
  const { pathname } = useLocation()

  const pagesDuplicated =
    page === 1
      ? [1, 2, 3, totalPages]
      : [1, page - 1, page, page + 1, totalPages]

  const pages = pagesDuplicated
    .filter((item, pos) => pagesDuplicated.indexOf(item) == pos)
    .filter((page) => page >= 1 && page <= totalPages)

  return (
    <nav className="border-t border-c-aside-separator px-4 flex items-center justify-between sm:px-0">
      <div className="-mt-px w-0 flex-1 flex">
        {page > 1 && (
          <Link
            to={`${pathname}${
              page - 1 === 1 ? '' : `?page=${encodeURIComponent(page - 1)}`
            }`}
            className="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-c-nav-text-dark hover:text-c-nav-text-dark-hover hover:border-c-nav-text-dark-hover"
          >
            <span className="mr-3">
              <Icon type="arrow-narrow-left" width={6} height={6} />
            </span>
            Previous
          </Link>
        )}
      </div>
      <div className="hidden md:-mt-px md:flex">
        {pages.map((nextPage, i) => (
          <>
            <Link
              to={`${pathname}${
                nextPage === 1 ? '' : `?page=${encodeURIComponent(nextPage)}`
              }`}
              className={`${
                nextPage === page
                  ? 'border-c-nav-text-dark text-text-dark'
                  : 'border-transparent text-c-nav-text hover:text-c-nav-text-hover hover:border-c-nav-text-hover'
              } border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium`}
            >
              {nextPage}
            </Link>
            {i < pages.length - 1 && pages[i + 1] !== nextPage + 1 && (
              <span className="border-transparent text-c-nav-text border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium">
                ...
              </span>
            )}
          </>
        ))}
      </div>
      <div className="-mt-px w-0 flex-1 flex justify-end">
        {page < totalPages && (
          <Link
            to={`${pathname}${
              page + 1 === 1 ? '' : `?page=${encodeURIComponent(page + 1)}`
            }`}
            className="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-c-nav-text-dark hover:text-c-nav-text-dark-hover hover:border-c-nav-text-dark-hover"
          >
            Next
            <span className="ml-3">
              <Icon type="arrow-narrow-right" width={6} height={6} />
            </span>
          </Link>
        )}
      </div>
    </nav>
  )
}
