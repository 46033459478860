import React from 'react'
import { Link } from 'react-router-dom'
import { MoneyBackGuarantee } from '../../components/elements/moneyback'
import { DELIVERY_WORKING_DAYS, useDeliveryDay } from '../../hooks/deliveryday'

export const HomeCta = () => {
  const deliveryDay = useDeliveryDay()

  return (
    <div className="bg-c-inverse-bg">
      <div className="max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <h2 className="text-3xl font-extrabold tracking-tight text-white sm:text-4xl">
          <span className="block">Request pricing for our Site Audits</span>
          <span className="block">
          or Consultancy Services
          </span>
        </h2>
        <div className="mt-8 flex justify-center">
          <div className="inline-flex rounded-md shadow">
            <form method="get" action="/request">
              <button
                type="submit"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-c-primary-bg hover:bg-c-primary-bg-hover"
              >
                Request pricing
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
