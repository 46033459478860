import React from 'react'
import { format, parse } from 'date-fns'
import { Icon } from '../../../components/elements/icon'
import { gql } from '@apollo/client'
import { BlogPost_BlogPostFragment } from '../../../generated/types'
import { Link } from 'react-router-dom'
import Url from 'url-parse'
import { SafeContent } from '../../../components/elements/safecontent'

interface PostMetaDetailsProps {
  author: React.ReactNode
  time: React.ReactNode
}

interface PostMetaTimeProps {
  date: Date
}

interface BlogPostProps {
  post: BlogPost_BlogPostFragment
}

export const BLOGPOST_BLOGPOST_FRAGMENT = gql`
  fragment BlogPost_blogPost on BlogPost {
    title
    safeContentTeaser
    featuredMedia {
      sourceUrl
      altText
    }
    author {
      name
      avatarUrls {
        size
        url
      }
    }
    link
    date
  }
`

const PostContainer: React.FC = ({ children }) => (
  <div className="flex flex-col rounded-lg shadow-lg overflow-hidden mb-12">
    {children}
  </div>
)

const PostHeader: React.FC = ({ children }) => (
  <div className="flex-shrink-0">{children}</div>
)

const PostBody: React.FC = ({ children }) => (
  <div className="flex-1 bg-white p-6 flex flex-col justify-between">
    {children}
  </div>
)

const PostContent: React.FC = ({ children }) => (
  <div className="flex-1">{children}</div>
)

export const PostMeta: React.FC = ({ children }) => (
  <div className="mt-6 mb-6 flex items-center">{children}</div>
)

export const PostAuthorIcon: React.FC = ({ children }) => (
  <div className="flex-shrink-0">{children}</div>
)

export const PostMetaDetails: React.FC<PostMetaDetailsProps> = ({
  author,
  time,
}) => (
  <div className="ml-3">
    <div className="text-sm font-medium text-c-label-text">{author}</div>
    <div className="flex space-x-1 text-sm text-c-label-text">{time}</div>
  </div>
)

export const PostMetaTime: React.FC<PostMetaTimeProps> = ({ date }) => (
  <time dateTime={format(date, 'yyyy-MM-dd')}>
    {format(date, 'd MMMM yyyy')}
  </time>
)

export const BlogPostLoading = () => (
  <PostContainer>
    <PostHeader>
      <div className="bg-c-loading-bg placeholder-pb-50 sm:placeholder-pb-none sm:h-64 lg:h-96" />
    </PostHeader>
    <PostBody>
      <PostContent>
        <div className="bg-c-loading-bg text-3xl leading-8 font-extrabold tracking-tight">
          &nbsp;
        </div>
        <PostMeta>
          <PostAuthorIcon>
            <div className="text-c-loading-text">
              <Icon type="user-circle" width={12} height={12} />
            </div>
          </PostAuthorIcon>
          <PostMetaDetails
            author={<div className="bg-c-loading-bg text-sm">&nbsp;</div>}
            time={<div className="bg-c-loading-bg text-sm w-40">&nbsp;</div>}
          />
        </PostMeta>
        <div className="mt-5 text-lg text-base bg-c-loading-bg">&nbsp;</div>
      </PostContent>
    </PostBody>
  </PostContainer>
)

export const BlogPost: React.FC<BlogPostProps> = ({
  post: {
    title,
    safeContentTeaser,
    featuredMedia,
    author: { name: authorName, avatarUrls },
    link,
    date,
  },
}) => {
  const linkPathname = new Url(link).pathname

  return (
    <PostContainer>
      {featuredMedia && (
        <PostHeader>
          <div className="bg-c-loading-bg">
            <Link to={linkPathname}>
              <img
                className="sm:h-64 lg:h-96 w-full object-cover"
                src={featuredMedia.sourceUrl}
                alt={featuredMedia.altText}
              />
            </Link>
          </div>
        </PostHeader>
      )}

      <PostBody>
        <PostContent>
          <h2 className="text-3xl leading-8 font-extrabold tracking-tight text-c-heading-text">
            <Link
              to={linkPathname}
              className="text-c-heading-text hover:text-c-link-text"
            >
              {title}
            </Link>
          </h2>
          <PostMeta>
            <PostAuthorIcon>
              <img
                className="h-12 w-12 rounded-full"
                src={avatarUrls.find(({ size }) => size === 48)?.url}
                srcSet={`${avatarUrls.find(({ size }) => size === 96)?.url} 2x`}
                alt=""
              />
            </PostAuthorIcon>
            <PostMetaDetails
              author={
                <p className="text-sm font-medium text-c-label-text">
                  {authorName}
                </p>
              }
              time={
                <PostMetaTime
                  date={parse(date, "yyyy-MM-dd'T'HH:mm:ss", new Date())}
                />
              }
            />
          </PostMeta>
          <SafeContent className="prose prose-lg" html={safeContentTeaser} />
          <p className="mt-10 mb-5">
            <Link
              to={linkPathname}
              className="px-5 py-3 border border-transparent text-base font-medium rounded-md text-c-nav-text-dark hover:text-c-nav-text-dark-hover bg-c-secondary-bg hover:bg-c-secondary-bg-hover"
            >
              Read more
            </Link>
          </p>
        </PostContent>
      </PostBody>
    </PostContainer>
  )
}
