/* This example requires Tailwind CSS v2.0+ */
import React, { useState } from 'react'
import { Icon } from '../../elements/icon'

export const Hero30Off = () => {
  const [show, setShow] = useState(true)

  return show ? (
    <div className="mt-6 relative bg-c-complement-bg">
      <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div className="pr-16 sm:text-center sm:px-16">
          <p className="font-medium text-white">
            Code <strong className="font-extrabold">30OFF</strong> applied
            &mdash; checkout now to get £30 off your first order
          </p>
        </div>
        <div className="absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pt-1 sm:pr-2 sm:items-start">
          <button
            type="button"
            className="flex p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-white text-white"
            onClick={() => setShow(false)}
          >
            <span className="sr-only">Dismiss</span>
            <Icon type="x" width={6} height={6} />
          </button>
        </div>
      </div>
    </div>
  ) : null
}
