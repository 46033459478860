import { gql, useQuery } from '@apollo/client'
import React from 'react'
import { SiteHeader } from '../../components/compounds/header'
import { PageTitle } from '../../components/elements/title'
import { ThankYouQuery } from '../../generated/types'

const THANK_YOU_QUERY = gql`
  query ThankYou {
    order {
      email
    }
  }
`

export const ThankYou = () => {
  const { loading, error, data, refetch } = useQuery<ThankYouQuery>(
    THANK_YOU_QUERY,
    {
      notifyOnNetworkStatusChange: true,
    },
  )

  if (error) {
    throw error
  }

  if (loading) {
    return <div>loading</div>
  }

  if (!data) {
    throw new Error('expected data')
  }
  return (
    <>
      <SiteHeader funnel />
      <PageTitle>Thank you - Recoreo</PageTitle>
      <div className="mt-16 sm:mt-24">
        <div className="mx-auto max-w-7xl">
          <div className="px-4 sm:px-6 max-w-3xl">
            <h1 className="text-3xl sm:text-4xl tracking-tight font-extrabold text-c-heading-text leading-8">
              Thank you for your order
            </h1>
            <p className="mt-6 text-c-primary-text">
              Once we accept your order, we will send a confirmation email to{' '}
              {data.order.email}. If you require any help, please contact us at{' '}
              <a
                className="underline text-c-link-text"
                href="mailto:support@recoreo.com"
              >
                support@recoreo.com
              </a>
              .
            </p>
          </div>
          <div className="mt-6 prose prose-lg md:prose-xl mx-auto"></div>
        </div>
      </div>
    </>
  )
}
