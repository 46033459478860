import { ErrorMessage, useFormikContext } from 'formik'
import React, { useRef } from 'react'
import { Field as FormikField } from 'formik'
import { Icon, SmallIcon } from '../../components/elements/icon'

interface FormHeadingProps {
  heading: string
  description: string
}

interface FormSectionProps {
  header: React.ReactNode
  first?: boolean
}

export enum FieldLabelSize {
  SMALL,
  NORMAL,
  WIDE,
}

interface FieldLabelProps {
  label: string
  size?: FieldLabelSize
  after?: React.ReactNode
}

export const FormHeader: React.FC<FormHeadingProps> = ({
  heading,
  description,
}) => (
  <>
    <h3 className="text-lg font-medium leading-6 text-c-heading-text">
      {heading}
    </h3>
    <p className="mt-1 text-sm text-c-secondary-primary-text">{description}</p>
  </>
)

export const FormSection: React.FC<FormSectionProps> = ({
  first,
  header,
  children,
}) => (
  <div className={first ? '' : 'mt-10 sm:mt-0'}>
    <div className="md:grid md:grid-cols-3 md:gap-6">
      <div className="md:col-span-1">
        <div className="px-0">{header}</div>
      </div>
      <div className="mt-5 md:mt-0 md:col-span-2">
        <div className="shadow sm:rounded-md sm:overflow-hidden">
          <div className="px-4 py-5 bg-white space-y-6 sm:p-6">{children}</div>
        </div>
      </div>
    </div>
  </div>
)

export const FormSectionSeparator = () => (
  <div className="hidden sm:block" aria-hidden="true">
    <div className="py-5">
      <div className="border-t border-c-secondary-separator"></div>
    </div>
  </div>
)

export const FieldRow: React.FC = ({ children }) => (
  <div className="grid grid-cols-6 gap-6">{children}</div>
)

export const FieldHeaded: React.FC<FieldLabelProps> = ({
  label,
  size = FieldLabelSize.NORMAL,
  children,
}) => (
  <div
    className={
      size === FieldLabelSize.SMALL
        ? 'col-span-2 sm:col-span-1'
        : size === FieldLabelSize.WIDE
        ? 'col-span-6 sm:col-span-4'
        : 'col-span-6 sm:col-span-3'
    }
  >
    <strong className="block text-sm font-medium text-c-label-text">
      {label}
    </strong>
    <div className="mt-1">{children}</div>
  </div>
)

export const FieldLabel: React.FC<FieldLabelProps> = ({
  label,
  size = FieldLabelSize.NORMAL,
  after,
  children,
}) => (
  <div
    className={
      size === FieldLabelSize.SMALL
        ? 'col-span-2 sm:col-span-1'
        : size === FieldLabelSize.WIDE
        ? 'col-span-6 sm:col-span-4'
        : 'col-span-6 sm:col-span-3'
    }
  >
    <label className="block text-sm font-medium text-c-label-text">
      {label}
      <div className="mt-1">{children}</div>
    </label>
    {after}
  </div>
)

type FieldProps = React.ComponentProps<typeof FormikField>

export const FieldCheckbox: React.FC<FieldProps> = ({
  name,
  label,
  ...props
}) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<any>()
  const ref = useRef<HTMLDivElement>(null)

  const onClick = () => {
    const value = values[name]

    if (typeof value !== 'boolean') {
      throw new Error(`Field ${name} must always be a boolean`)
    }

    setFieldTouched(name)
    setFieldValue(name, !value)
  }

  const onBlur = () => {
    setFieldTouched(name)
  }

  const value = values[name]

  if (typeof value !== 'boolean') {
    throw new Error(`Field ${name} must always be a boolean`)
  }

  return (
    <>
      <div className="sr-only" ref={ref}>
        <FormikField tabIndex={-1} name={name} {...props} />
        {label}
      </div>
      <div
        tabIndex={0}
        aria-role="none"
        className="cursor-pointer py-2 px-3 focus:ring-c-focus-ring outline-none focus:ring-1 border border-c-input-border focus:border-c-focus-border block w-full shadow-sm text-sm rounded-md"
        onClick={onClick}
        onBlur={onBlur}
      >
        <div className="flex">
          <div className="flex-none mr-3">
            <div
              className={`border ${
                value
                  ? 'border-transparent bg-c-checkbox-icon-bg'
                  : 'border-c-input-border'
              } text-white rounded-md`}
            >
              <SmallIcon type={'check'} width={5} height={5} />
            </div>
          </div>
          <div className="flex-1">{label}</div>
        </div>
      </div>
    </>
  )
}

export const Field: React.FC<FieldProps> = (props) => (
  <FormikField
    className="focus:ring-c-focus-ring border-c-input-border focus:border-c-focus-border block w-full shadow-sm sm:text-sm rounded-md"
    {...props}
  />
)

interface FieldErrorMessageProps {
  name: string
  message?: string
}

export const FieldErrorMessage: React.FC<FieldErrorMessageProps> = ({
  name,
  message,
}) => (
  <ErrorMessage name={name}>
    {(msg) => (
      <div className="mt-4 rounded-md bg-c-error-bg p-4">
        <div className="flex">
          <div className="flex-shrink-0 text-c-error-text">
            <SmallIcon type="x-circle" width={5} height={5} />
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-c-error-text">
              {message ?? msg}
            </p>
          </div>
        </div>
      </div>
    )}
  </ErrorMessage>
)
