import { gql, useQuery } from '@apollo/client'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { PromotionsContext } from './contexts/promotions'
import {
  GetPromotionsQuery,
  GetPromotionsQueryVariables,
} from './generated/types'

const PROMOTION_QS_KEY = 'rp'

const GET_PROMOTIONS = gql`
  query GetPromotions($newPromotion: String) {
    userPromotions(newPromotion: $newPromotion) {
      id
      promotions {
        id
      }
    }
  }
`

const PROMOTION_QS_REGEX = new RegExp(`[?&]${PROMOTION_QS_KEY}=([^?&]*)`)

export const GlobalPromotionsProvider: React.FC = ({ children }) => {
  const location = useLocation()
  const { data } = useQuery<GetPromotionsQuery, GetPromotionsQueryVariables>(
    GET_PROMOTIONS,
    {
      variables: {
        newPromotion: location.search.match(PROMOTION_QS_REGEX)?.[1] ?? null,
      },
    },
  )

  return (
    <PromotionsContext.Provider
      value={{ promotions: data?.userPromotions.promotions ?? [] }}
    >
      {children}
    </PromotionsContext.Provider>
  )
}
