import React from 'react'
import { Footer } from '../../components/compounds/footer'
import { SiteHeader } from '../../components/compounds/header'
import { PageTitle } from '../../components/elements/title'

const TermsContent = () => (
  <>
    <h1>Terms and Conditions</h1>
    <p>
      Please read these Terms and Conditions carefully. All contracts that the
      Provider may enter into from time to time shall be governed by these Terms
      and Conditions, and the Provider will ask the Client for the Client's
      express acceptance of these Terms and Conditions before providing to the
      Client.
    </p>
    <h2>1. Definitions</h2>
    <p>
      <strong>1.1.</strong> In these Terms and Conditions:
    </p>
    <ul>
      <li>
        <strong>"Acceptance Criteria"</strong> means compliance with the
        warranties set out in Clause 4.4;
      </li>
      <li>
        <strong>"Business Day"</strong> means any weekday other than a bank or
        public holiday in England;
      </li>
      <li>
        <strong>"Charges"</strong> means:
        <ol type="a">
          <li>
            the amounts specified in the Services Order Form and elsewhere in
            these Terms and Conditions; and
          </li>
          <li>
            such other amounts as may be agreed by the parties from time to
            time;
          </li>
        </ol>
      </li>
      <li>
        <strong>"Client"</strong> means the person or entity identified as such
        in the Services Order Form;
      </li>

      <li>
        <strong>"Client Materials"</strong> means all works and materials
        supplied by or on behalf of the Client to the Prodiver for incorporation
        into the Deliverables or for some other use in connection with the
        Services;
      </li>
      <li>
        <strong>"Client Personal Data"</strong> means any Personal Data that is
        processed by the Prodiver on behalf of the Client in relation to the
        Contract;
      </li>
      <li>
        <strong>"Contract"</strong> means a particular contract made under these
        Terms and Conditions between the Provider and the Client;
      </li>
      <li>
        <strong>"Data Protection Laws"</strong> means the EU GDPR and the UK
        GDPR;
      </li>
      <li>
        <strong>"Deliverables"</strong> means those deliverables specified in
        the Services Order Form that the Provider has subsequently agreed to
        deliver to the Client under these Terms and Conditions;
      </li>
      <li>
        <strong>"Effective Date"</strong> means, following the Client completing
        and submitting the online Services Order Form published by the Provider
        on the Provider's website, the date upon which the Provider sends to the
        Client an order confirmation;
      </li>
      <li>
        <strong>"EU GDPR"</strong> means the General Data Protection Regulation
        (Regulation (EU) 2016/679) and all other EU laws regulating the
        processing of Personal Data, as such laws may be updated, amended and
        superseded from time to time;
      </li>
      <li>
        <strong>"Force Majeure Event"</strong> means an event, or a series of
        related events, that is outside the reasonable control of the party
        affected (including failures of the internet or any public
        telecommunications network, hacker attacks, denial of service attacks,
        virus or other malicious software attacks or infections, power failures,
        industrial disputes affecting any third party, changes to the law,
        disasters, epidemics, pandemics, explosions, fires, floods, riots,
        terrorist attacks and wars);
      </li>
      <li>
        <strong>"Intellectual Property Rights"</strong> means all intellectual
        property rights wherever in the world, whether registrable or
        unregistrable, registered or unregistered, including any application or
        right of application for such rights (and these "intellectual property
        rights" include copyright and related rights, database rights,
        confidential information, trade secrets, know-how, business names, trade
        names, trade marks, service marks, passing off rights, unfair
        competition rights, patents, petty patents, utility models,
        semi-conductor topography rights and rights in designs);
      </li>
      <li>
        <strong>"Personal Data"</strong> means personal data under any of the
        Data Protection Laws;
      </li>
      <li>
        <strong>"Provider"</strong> means Recoreo Ltd, a company incorporated in
        England and Wales (registration number 10005255) having its registered
        office at Recoreo Ltd, 20-22 Wenlock Road, London, England, N1 7GU;
      </li>
      <li>
        <strong>"Services"</strong> means any writing services that the Provider
        provides to the Client, or has an obligation to provide to the Client,
        under these Terms and Conditions;
      </li>
      <li>
        <strong>"Services Order Form"</strong> means an online order form
        published by the Provider and completed and submitted by the Client in
        each case incorporating these Terms and Conditions by reference;
      </li>
      <li>
        <strong>"Term"</strong> means the term of the Contract, commencing in
        accordance with Clause 2.1 and ending in accordance with Clause 2.2;
      </li>
      <li>
        <strong>"Terms and Conditions"</strong> means all the documentation
        containing the provisions of the Agreement, namely the Services Order
        Form and the main body of these Terms and Conditions, including any
        amendments to that documentation from time to time;
      </li>
      <li>
        <strong>"Third Party Materials"</strong> means the works and/or
        materials comprised in the Deliverables (excluding the Client
        Materials), the Intellectual Property Rights in which are owned by a
        third party; and
      </li>
      <li>
        <strong>"UK GDPR"</strong> means the EU GDPR as transposed into UK law
        (including by the Data Protection Act 2018 and the Data Protection,
        Privacy and Electronic Communications (Amendments etc) (EU Exit)
        Regulations 2019) and all other UK laws regulating the processing of
        Personal Data, as such laws may be updated, amended and superseded from
        time to time.
      </li>
    </ul>
    <h2>2. Term</h2>
    <p>
      <strong>2.1.</strong> The Contract shall come into force upon the
      Effective Date.
    </p>
    <p>
      <strong>2.2.</strong> The Contract shall continue in force until the
      acceptance of the Deliverables in accordance with Clause 5, subject to
      termination in accordance with Clause 18.
    </p>
    <p>
      <strong>2.3.</strong> Unless the parties expressly agree otherwise in
      writing, each Services Order Form shall create a distinct contract under
      these Terms and Conditions.
    </p>
    <h2>3. Services</h2>
    <p>
      <strong>3.1.</strong> The Provider shall provide the Services to the
      Client in accordance with these Terms and Conditions.
    </p>
    <h2>4. Deliverables</h2>
    <p>
      <strong>4.1.</strong> The Provider shall deliver the Deliverables to the
      Client.
    </p>
    <p>
      <strong>4.2.</strong> The Client must promptly, following receipt of a
      written request from the Provider to do so, provide written feedback to
      the Prodiver concerning the Provider's proposals, plans, designs and/or
      preparatory materials relating to the Deliverables and made available to
      the Client with that written request.
    </p>
    <p>
      <strong>4.3.</strong> The Provider shall use reasonable endeavours to
      ensure that the Deliverables are delivered to the Client in accordance
      with the timetable set out in the Services Order Form.
    </p>
    <p>
      <strong>4.4.</strong> The Provider warrants to the Client that the
      Deliverables will be free from material defects.
    </p>
    <h2>5. Acceptance</h2>
    <p>
      <strong>5.1.</strong> Within 10 Business Days following the delivery of
      Deliverables to the Client, the Client shall:
    </p>
    <ol type="a">
      <li>
        review the Deliverables to determine whether they comply with the
        Acceptance Criteria; and
      </li>
      <li>
        notify the Provider in writing of the results of such review, providing
        full details of any non-compliance with the Acceptance Criteria.
      </li>
    </ol>
    <p>
      <strong>5.2.</strong> If the Client does not give to the Provider a notice
      under Clause 5.1, within the period referred to in Clause 5.1, then the
      Deliverables shall be deemed to meet the Acceptance Criteria.
    </p>
    <p>
      <strong>5.3.</strong> If the Deliverables do not comply with the
      Acceptance Criteria and the Client notifies the Provider of the
      non-compliance in accordance with this Clause 5, the Provider will have a
      further period of no more than 20 Business Days to remedy the
      non-compliance, following which Client will repeat the review.
    </p>
    <p>
      <strong>5.4.</strong> If the Deliverables do not meet the Acceptance
      Criteria at the time of a second (or subsequent) acceptance review under
      this Clause 5, then Provider shall be deemed in breach of the Contract.
    </p>
    <p>
      <strong>5.5.</strong> If the Client accepts or is deemed to accept the
      Deliverables under this Clause 5, then subject to Clause 10.1 the Client
      will have no right to make any claim under or otherwise rely upon Clause
      4.4 unless the Client could not reasonably have been expected to have
      identified the breach of that provision during the testing or review
      process.
    </p>
    <h2>6. Intellectual Property Rights</h2>
    <p>
      <strong>6.1.</strong> The Provider hereby assigns to the Client all of its
      Intellectual Property Rights in the Deliverables, whether those
      Intellectual Property Rights exist on the Effective Date or come into
      existence during the Term, excluding the Intellectual Property Rights in
      the Client Materials and the Third Party Materials. This assignment is for
      the full term of the assigned rights, including all extensions, renewals,
      reversions and revivals, and includes the right to bring proceedings for
      past infringements of the assigned rights.
    </p>
    <p>
      <strong>6.2.</strong> To the maximum extent permitted by applicable law:
    </p>
    <ol type="a">
      <li>
        the Provider irrevocably and unconditionally waives all moral rights
        (including rights of paternity and rights of integrity) in respect of
        the Deliverables to which the Provider may at any time be entitled; and
      </li>
      <li>
        the Provider undertakes to ensure that all individuals involved in the
        preparation of the Deliverables will irrevocably and unconditionally
        waive all moral rights (including rights of paternity and rights of
        integrity) in respect of the Deliverables to which they may at any time
        be entitled.
      </li>
    </ol>
    <h2>7. Charges</h2>
    <p>
      <strong>7.1.</strong> The Client shall pay the Charges to the Provider in
      accordance with these Terms and Conditions.
    </p>
    <p>
      <strong>7.2.</strong> All amounts stated in or in relation to these Terms
      and Conditions are, unless the context requires otherwise, stated
      exclusive of any applicable value added taxes, which will be added to
      those amounts and payable by the Client to the Provider.
    </p>
    <h2>8. Payments</h2>
    <p>
      <strong>8.1.</strong> The Provider shall issue invoices for the Charges to
      the Client in advance of the delivery of the relevant Services to the
      Client.
    </p>
    <p>
      <strong>8.2.</strong> The Client must pay the Charges to the Provider
      within the period of 7 Business Days following the issue of an invoice in
      accordance with this Clause 8.
    </p>
    <p>
      <strong>8.3.</strong> The Client must pay the Charges by debit card or
      credit card (using such payment details as are notified by the Provider to
      the Client from time to time).
    </p>
    <h2>9. Data protection</h2>
    <p>
      <strong>9.1.</strong> The Client warrants to the Provider that it has the
      legal right to disclose all Personal Data that it does in fact disclose to
      the Provider under or in connection with the Contract.
    </p>
    <p>
      <strong>9.2.</strong> The Client shall only supply to the Provider, and
      the Provider shall only process, in each case under or in relation to the
      Contract, the Personal Data of the Client's officers, employees and
      contractors of the following types: contact details; and the Provider
      shall only process the Client Personal Data for the following purposes:
      contacting or sending the Deliverables to the data subject.
    </p>
    <p>
      <strong>9.3.</strong> The Provider shall only process the Client Personal
      Data during the Term, subject to the other provisions of this Clause 9.
    </p>
    <p>
      <strong>9.4.</strong> The Provider shall only process the Client Personal
      Data on the documented instructions of the Client (including with regard
      to transfers of the Client Personal Data to a third country under the Data
      Protection Laws), as set out in these Terms and Conditions or any other
      document agreed by the parties in writing.
    </p>
    <p>
      <strong>9.5.</strong> Notwithstanding any other provision of the Contract,
      the Provider may process the Client Personal Data if and to the extent
      that the Provider is required to do so by applicable law. In such a case,
      the Provider shall inform the Client of the legal requirement before
      processing, unless that law prohibits such information.
    </p>
    <p>
      <strong>9.6.</strong> The Provider shall ensure that persons authorised to
      process the Client Personal Data have committed themselves to
      confidentiality or are under an appropriate statutory obligation of
      confidentiality.
    </p>
    <p>
      <strong>9.7.</strong> The Provider and the Client shall each implement
      appropriate technical and organisational measures to ensure an appropriate
      level of security for the Client Personal Data.
    </p>
    <p>
      <strong>9.8.</strong> The Provider must not engage any third party to
      process the Client Personal Data without the prior specific or general
      written authorisation of the Client. In the case of a general written
      authorisation, the Provider shall inform the Client at least 14 days in
      advance of any intended changes concerning the addition or replacement of
      any third party processor, and if the Client objects to any such changes
      before their implementation, then the Client may terminate the Contract on
      7 days' written notice to the Provider, providing that such notice must be
      given within the period of 7 days following the date that the Provider
      informed the Client of the intended changes. The Provider shall ensure
      that each third party processor is subject to equivalent legal obligations
      as those imposed on the Provider by this Clause 9.
    </p>
    <p>
      <strong>9.9.</strong> The Provider shall, insofar as possible and taking
      into account the nature of the processing, take appropriate technical and
      organisational measures to assist the Client with the fulfilment of the
      Client's obligation to respond to requests exercising a data subject's
      rights under the Data Protection Laws.
    </p>
    <p>
      <strong>9.10.</strong> The Provider shall assist the Client in ensuring
      compliance with the obligations relating to the security of processing of
      personal data, the notification of personal data breaches to the
      supervisory authority, the communication of personal data breaches to the
      data subject, data protection impact assessments and prior consultation in
      relation to high-risk processing under the Data Protection Laws. The
      Provider may charge the Client at its standard time-based charging rates
      for any work performed by the Provider at the request of the Client
      pursuant to this Clause 9.10.
    </p>
    <p>
      <strong>9.11.</strong> The Provider shall make available to the Client all
      information necessary to demonstrate the compliance of the Provider with
      its obligations under this Clause 9. The Provider may charge the Client at
      its standard time-based charging rates for any work performed by the
      Provider at the request of the Client pursuant to this Clause 9.11,
      providing that no such charges shall be levied with respect to the
      completion by the Provider (at the reasonable request of the Client, not
      more than once per calendar year).
    </p>
    <p>
      <strong>9.12.</strong> The Provider shall, at the choice of the Client,
      delete or return all of the Client Personal Data to the Client after the
      provision of services relating to the processing, and shall delete
      existing copies save to the extent that applicable law requires storage of
      the relevant Personal Data.
    </p>
    <p>
      <strong>9.13.</strong> The Provider shall allow for and contribute to
      audits, including inspections, conducted by the Client or another auditor
      mandated by the Client in respect of the compliance of the Provider's
      processing of Client Personal Data with the Data Protection Laws and this
      Clause 9. The Provider may charge the Client at its standard time-based
      charging rates for any work performed by the Provider at the request of
      the Client pursuant to this Clause 9.13.
    </p>
    <h2>10. Limitations and exclusions of liability</h2>
    <p>
      <strong>10.1.</strong> Nothing in these Terms and Conditions will:
    </p>
    <ol type="a">
      <li>
        limit or exclude any liability for death or personal injury resulting
        from negligence;
      </li>
      <li>
        limit or exclude any liability for fraud or fraudulent
        misrepresentation;
      </li>
      <li>
        limit any liabilities in any way that is not permitted under applicable
        law; or
      </li>
      <li>
        exclude any liabilities that may not be excluded under applicable law.
      </li>
    </ol>
    <p>
      <strong>10.2.</strong> The limitations and exclusions of liability set out
      in this Clause 10 and elsewhere in these Terms and Conditions:
    </p>
    <ol type="a">
      <li>are subject to Clause 10.1; and</li>
      <li>
        govern all liabilities arising under these Terms and Conditions or
        relating to the subject matter of these Terms and Conditions, including
        liabilities arising in contract, in tort (including negligence) and for
        breach of statutory duty, except to the extent expressly provided
        otherwise in these Terms and Conditions.
      </li>
    </ol>
    <p>
      <strong>10.3.</strong> The Provider will not be liable to the Client in
      respect of any losses arising out of a Force Majeure Event.
    </p>
    <p>
      <strong>10.4.</strong> The Provider will not be liable to the Client in
      respect of any loss of profits or anticipated savings.
    </p>
    <p>
      <strong>10.5.</strong> The Provider will not be liable to the Client in
      respect of any loss of revenue or income.
    </p>
    <p>
      <strong>10.6.</strong> The Provider will not be liable to the Client in
      respect of any loss of use or production.
    </p>
    <p>
      <strong>10.7.</strong> The Provider will not be liable to the Client in
      respect of any loss of business, contracts or opportunities.
    </p>
    <p>
      <strong>10.8.</strong> The Provider will not be liable to the Client in
      respect of any loss or corruption of any data, database or software.
    </p>
    <p>
      <strong>10.9.</strong> The Provider will not be liable to the Client in
      respect of any special, indirect or consequential loss or damage.
    </p>
    <p>
      <strong>10.10.</strong> The liability of the Provider to the Client under
      the Contract in respect of any event or series of related events shall not
      exceed the total amount paid and payable by the Client to the Provider
      under the Contract in the 12 month period preceding the commencement of
      the event or events.
    </p>
    <p>
      <strong>10.11.</strong> The aggregate liability of the Provider to the
      Client under the Contract shall not exceed the total amount paid and
      payable by the Client to the Provider under the Contract.
    </p>
    <h2>11. Termination</h2>
    <p>
      <strong>11.1.</strong> Either party may terminate the Contract by giving
      to the other party not less than 30 days' written notice of termination.
    </p>
    <p>
      <strong>11.2.</strong> Either party may terminate the Contract immediately
      by giving written notice of termination to the other party if:
    </p>
    <ol type="a">
      <li>the other party commits any material breach of the Contract; or</li>
      <li>
        the other party persistently breaches the Contract (irrespective of
        whether such breaches collectively constitute a material breach).
      </li>
    </ol>
    <p>
      <strong>11.3.</strong> Subject to applicable law, either party may
      terminate the Contract immediately by giving written notice of termination
      to the other party if:
    </p>
    <ol type="a">
      <li>
        the other party:
        <ol type="i">
          <li>is dissolved;</li>
          <li>ceases to conduct all (or substantially all) of its business;</li>
          <li>is or becomes unable to pay its debts as they fall due;</li>
          <li>is or becomes insolvent or is declared insolvent; or</li>
          <li>
            convenes a meeting or makes or proposes to make any arrangement or
            composition with its creditors;
          </li>
        </ol>
      </li>
      <li>
        an administrator, administrative receiver, liquidator, receiver,
        trustee, manager or similar is appointed over any of the assets of the
        other party; or
      </li>
      <li>
        an order is made for the winding up of the other party, or the other
        party passes a resolution for its winding up.
      </li>
    </ol>
    <h2>12. Effects of termination</h2>
    <p>
      <strong>12.1.</strong> Upon the termination of the Contract, all of the
      provisions of these Terms and Conditions shall cease to have effect, save
      that the following provisions of these Terms and Conditions shall survive
      and continue to have effect (in accordance with their express terms or
      otherwise indefinitely): Clauses 1, 5.5, 6.1, 8.2, 9, 10, 12 and 14.
    </p>
    <p>
      <strong>12.2.</strong> Except to the extent expressly provided otherwise
      in these Terms and Conditions, the termination of the Contract shall not
      affect the accrued rights of either party.
    </p>
    <h2>13. Subcontracting</h2>
    <p>
      <strong>13.1.</strong> Subject to any express restrictions elsewhere in
      these Terms and Conditions, the Provider may subcontract any of its
      obligations under the Contract.
    </p>
    <h2>14. General</h2>
    <p>
      <strong>14.1.</strong> If any provision of the Contract is determined by
      any court or other competent authority to be unlawful and/or
      unenforceable, the other provisions of the Contract will continue in
      effect.
    </p>
    <p>
      <strong>14.2.</strong> If any unlawful and/or unenforceable provision
      would be lawful or enforceable if part of it were deleted, that part will
      be deemed to be deleted, and the rest of the provision will continue in
      effect (unless that would contradict the clear intention of the parties,
      in which case the entirety of the relevant provision will be deemed to be
      deleted).
    </p>
    <p>
      <strong>14.3.</strong> The Contract shall be governed by and construed in
      accordance with English law.
    </p>
    <p>
      <strong>14.4.</strong> The courts of England shall have exclusive
      jurisdiction to adjudicate any dispute arising under or in connection with
      the Contract.
    </p>
  </>
)

export const TermsAndConditions = () => {
  return (
    <>
      <SiteHeader />
      <PageTitle>Terms and Conditions - Recoreo</PageTitle>
      <div className="relative py-16 bg-white overflow-hidden">
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="mt-6 prose prose-lg md:prose-xl mx-auto">
            <TermsContent />
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
