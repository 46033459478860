import React, { useEffect, useRef } from 'react'
import { renderToString } from 'react-dom/server'
import { Link, useHistory } from 'react-router-dom'
import regexifyString from 'regexify-string'

type Props = {
  html: string
  className?: string
}

const findAnchorEl = (el: Node): Element | null => {
  if (el instanceof Element && el.tagName.toLowerCase() === 'a') {
    return el
  }

  return el.parentNode ? findAnchorEl(el.parentNode) : null
}

export const SafeContent: React.FC<Props> = ({ html, ...rest }) => {
  const ref = useRef<HTMLDivElement>(null)
  const history = useHistory()

  useEffect(() => {
    ref.current?.addEventListener('click', (e) => {
      const { target } = e

      if (!(target instanceof Element)) {
        return
      }

      const el = findAnchorEl(target)
      const href = el?.getAttribute('href')

      if (!href) {
        return
      }

      const [_, hostname, pathname] =
        href.match(/^https\:\/\/(.*?)(\/.*)$/) ?? []

      if (hostname === window.location.hostname) {
        e.preventDefault()

        history.push(pathname)
      }
    })
  }, [])

  return <div ref={ref} {...rest} dangerouslySetInnerHTML={{ __html: html }} />
}
