import React from 'react'
import { Field } from '../../../pages/start/multipartform'

type Props = React.ComponentProps<typeof Field>

interface Country {
  code: string
  label: string
  sortKey: string
  collectEuVatNumber: boolean
}

export const COUNTRIES: Country[] = `
AT,Austria,,true
BE,Belgium,,true
BG,Bulgaria,,true
HR,Croatia,,true
CY,Cyprus,,true
CZ,Czech Republic,,true
DK,Denmark,,true
EE,Estonia,,true
FI,Finland,,true
FR,France,,true
DE,Germany,,true
EL,Greece,,true
HU,Hungary,,true
IE,Ireland,,true
IT,Italy,,true
LV,Latvia,,true
LT,Lithuania,,true
LU,Luxembourg,,true
MT,Malta,,true
NL,Netherlands,,true
PL,Poland,,true
PT,Portugal,,true
RO,Romania,,true
SK,Slovak Republic,,true
SI,Slovenia,,true
ES,Spain,,true
SE,Sweden,,true
US,United States,,false
GB,United Kingdom,,false
CA,Canada,,false
AU,Australia,,false
ZA,South Africa,,false
NZ,New Zealand,,false
`
  .split(/\n/g)
  .filter((line) => !!line.trim())
  .map((line) => {
    const parts = line.split(/,/g)

    return {
      code: parts[0],
      label: parts[1],
      sortKey: parts[2] || parts[1],
      collectEuVatNumber: parts[3] === 'true',
    }
  })

export const CountrySelectField: React.FC<Props> = (props) => {
  return (
    <Field as="select" {...props}>
      <option value=""></option>
      {[...COUNTRIES]
        .sort((obj1, obj2) => (obj1.sortKey > obj2.sortKey ? 1 : -1))
        .map(({ code, label }) => (
          <option key={code} value={code}>
            {label}
          </option>
        ))}
    </Field>
  )
}
