import { loadStripe } from '@stripe/stripe-js'
import { createContext } from 'react'

interface StripeContext {
  loadStripe: () => ReturnType<typeof loadStripe>
}

export const StripeContext = createContext<StripeContext>({
  loadStripe: () => Promise.resolve(null),
})
