import React from 'react'

export const Logo = () => (
  <img
    src="/img/recoreo-logo-129.png"
    srcSet="/img/recoreo-logo-129@2x.png 2x"
    width="129"
    height="40"
    alt=""
  />
)
