import { useFormikContext } from 'formik'
import React from 'react'
import { useEffect, useRef, useState } from 'react'
import Measure from 'react-measure'
import { format } from 'date-fns'
import { Values } from '.'
import { Icon } from '../../components/elements/icon'

export const TRIAL_PREFIX = 'AAA 30, 2021 — '

interface GoogleLinesRenderProps {
  lines: string[]
  lineWidths: number[]
  isError: boolean
  isWarning: boolean
  progress: number
}

interface TextProgressProps {
  name: keyof Values
  containerWidth: number
  lastLineErrorPx: number
  warningProgress: number
  maxLines?: number
  prefix?: string
  fontSize: 14 | 20
  children: (props: GoogleLinesRenderProps) => React.ReactNode
}

export const GoogleLinesProvider: React.FC<TextProgressProps> = ({
  name,
  containerWidth,
  lastLineErrorPx,
  warningProgress,
  maxLines = 1,
  prefix = '',
  fontSize,
  children,
}) => {
  const { values } = useFormikContext<Values>()
  const [{ lineWidths, lines }, setLineData] = useState<{
    lineWidths: number[]
    lines: string[]
  }>({
    lineWidths: [],
    lines: [],
  })
  const [controlHeight, setControlHeight] = useState(0)
  const evalRef = useRef<HTMLDivElement>(null)
  const value = values[name]

  if (typeof value !== 'string') {
    throw new Error('expected string')
  }

  const onControlResize: React.ComponentProps<typeof Measure>['onResize'] = (
    contentRect,
  ) => {
    setControlHeight(contentRect.bounds?.height ?? 0)
  }

  useEffect(() => {
    const words = `${prefix}${value}`.trim().replace(/\s+/g, ' ').split(/\s/g)
    const lines: string[] = []
    const el = evalRef.current

    if (!el) {
      throw new Error('Expected evalRef')
    }

    while (lines.length < maxLines + 1 && words.length > 0) {
      let line = ''

      while (words.length > 0) {
        const trialLine = `${line} ${words[0]}${
          lines.length === maxLines - 1 ? ' ...' : ''
        }`.trim()
        const lineMaxWidth =
          lines.length === maxLines - 1 ? lastLineErrorPx : containerWidth

        el.style.maxWidth = `${lineMaxWidth}px`
        el.innerText = trialLine

        const trialLines = Math.max(
          1,
          controlHeight > 0 ? Math.floor(el.clientHeight / controlHeight) : 0,
        )

        el.style.maxWidth = 'none'

        if (trialLines === 1 && el.clientWidth <= lineMaxWidth) {
          line = `${line} ${words.shift()}`.trim()
        } else {
          break
        }
      }

      lines.push(line)
    }

    const lineWidths = lines.map((line, i) => {
      if (i !== lines.length - 1) {
        return containerWidth
      } else {
        el.innerText = line
        return el.clientWidth
      }
    })

    setLineData({
      lines,
      lineWidths,
    })
  }, [value])

  const totalAvailable = lastLineErrorPx + (maxLines - 1) * containerWidth
  const totalUsed = lineWidths.reduce(
    (total, lineWidth) => total + lineWidth,
    0,
  )
  const progress = value.trim() === '' ? 0 : totalUsed / totalAvailable

  const isError = lineWidths.length > maxLines
  const isWarning = progress < warningProgress

  return (
    <>
      <div
        style={{
          width: '0px',
          height: '0px',
          overflow: 'hidden',
        }}
      >
        <div style={{ width: `${containerWidth}px` }}>
          <Measure bounds onResize={onControlResize}>
            {({ measureRef }) => (
              <div
                ref={measureRef}
                style={{
                  display: 'inline-block',
                  fontSize: `${fontSize}px`,
                  fontWeight: 400,
                  fontFamily: 'arial,sans-serif',
                }}
              >
                Control
              </div>
            )}
          </Measure>
          <div
            ref={evalRef}
            style={{
              display: 'inline-block',
              fontSize: `${fontSize}px`,
              fontWeight: 400,
              fontFamily: 'arial,sans-serif',
            }}
          />
        </div>
      </div>
      {children({
        lines,
        lineWidths,
        isError,
        isWarning,
        progress,
      })}
    </>
  )
}

export const GooglePreview = () => {
  const { values } = useFormikContext<Values>()
  const [width, setWidth] = useState(600)

  const onResize: React.ComponentProps<typeof Measure>['onResize'] = (
    contentRect,
  ) => {
    console.log(contentRect, Math.min(contentRect.bounds?.width ?? 600, 600))
    setWidth(Math.min(contentRect.bounds?.width ?? 600, 600))
  }

  return (
    <>
      <Measure bounds onResize={onResize}>
        {({ measureRef }) => (
          <div ref={measureRef} style={{ width: '100%', height: '0px' }} />
        )}
      </Measure>
      <GoogleLinesProvider
        name="blogPostTitle"
        containerWidth={600}
        lastLineErrorPx={600}
        warningProgress={1 - 1 / Math.E}
        fontSize={20}
      >
        {({
          lines: titleLines,
          isWarning: titleIsWarning,
          isError: titleIsError,
        }) => (
          <GoogleLinesProvider
            name="blogPostDescription"
            containerWidth={600}
            lastLineErrorPx={425}
            warningProgress={1 - 1 / (2 * Math.E)}
            fontSize={14}
            maxLines={2}
            prefix={TRIAL_PREFIX}
          >
            {({
              lines: bodyLines,
              isWarning: bodyIsWarning,
              isError: bodyIsError,
            }) => (
              <div className="border-l-8 border-c-callout-border -separator pl-4 pt-4 pb-4">
                <div className="flex">
                  <div
                    className={`flex items-center justify-center h-10 w-10 rounded-full ${
                      titleIsWarning ||
                      titleIsError ||
                      bodyIsWarning ||
                      bodyIsError
                        ? 'bg-c-icon-warning-bg text-c-icon-warning-text'
                        : 'bg-c-icon-notice-bg text-c-icon-notice-text'
                    }`}
                  >
                    {titleIsWarning ||
                    titleIsError ||
                    bodyIsWarning ||
                    bodyIsError ? (
                      <Icon type="exclamation" width={6} height={6} />
                    ) : (
                      <Icon type="thumb-up" width={6} height={6} />
                    )}
                  </div>
                </div>
                <h4 className="mt-4 text-md font-medium leading-6 text-c-heading-text">
                  Google Search (Desktop) Preview
                </h4>
                <p className="mt-1 text-sm text-c-secondary-primary-text">
                  This is how your blog post may appear on a Google results
                  page:
                </p>
                <div
                  className="mt-4"
                  style={{
                    width: '600px',
                    zoom: Math.floor((width / 600) * 100) / 100,
                  }}
                >
                  <div>
                    <div
                      style={{
                        display: 'inline-block',
                        fontSize: `20px`,
                        fontWeight: 400,
                        fontFamily: 'arial,sans-serif',
                        color: '#1a0dab',
                        marginBottom: '3px',
                        paddingTop: '5px',
                      }}
                    >
                      {titleLines[0] || <>☝️ First, add a post title</>}
                      {titleLines.length > 1 && ' ...'}
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        display: 'inline-block',
                        fontSize: `14px`,
                        lineHeight: '22.12px',
                        fontWeight: 400,
                        fontFamily: 'arial,sans-serif',
                        color: '#4d5156',
                      }}
                    >
                      <div>
                        <span style={{ color: '#70757a' }}>
                          {format(new Date(), 'dd MMM yyyy')} —{' '}
                        </span>
                        {(bodyLines[0] ?? '').substr(TRIAL_PREFIX.length)}
                      </div>
                      <div>
                        {bodyLines[1] || <>&nbsp;</>}
                        {bodyLines.length > 2 && ' ...'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </GoogleLinesProvider>
        )}
      </GoogleLinesProvider>
    </>
  )
}
