import React from 'react'
import { FunnelFooter } from '../../components/compounds/footer'
import { SiteHeader } from '../../components/compounds/header'
import { PageTitle } from '../../components/elements/title'

export const RequestPage: React.FC = () => {
  return (
    <>
      <SiteHeader funnel />
      <PageTitle>Recoreo - Request more information about our site audits and consultancy services.</PageTitle>
      <div className="bg-c-secondary-bg">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 py-8 lg:py-16">
          <div className="pb-8 lg:pb-16">
            <h1 className="text-3xl leading-8 font-extrabold tracking-tight text-c-heading-text sm:text-4xl">
              Request pricing 
            </h1>
            <div className="max-w-prose">
              <p className="mt-4 text-base text-c-secondary-primary-text">
              Request more information about our site audits and consultancy services.
              </p>
              
            </div>
            <div className="-mx-4 md:hidden">
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdm8twD7Cm4xiWIMJPhD6jz5ikeJwh4m_9QCy1KI78x3RFfzg/viewform?embedded=true" width="390" height="1853" frameBorder="0" marginHeight={0} marginWidth={0}>Loading…</iframe>
            </div>
            <div className="mt-16 -mx-4 hidden md:block">
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdm8twD7Cm4xiWIMJPhD6jz5ikeJwh4m_9QCy1KI78x3RFfzg/viewform?embedded=true" width="600" height="1853" frameBorder="0" marginHeight={0} marginWidth={0}>Loading…</iframe>
            </div>
          </div>
        </div>
      </div>
      <FunnelFooter />
    </>
  )
}
