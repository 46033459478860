import React, { useContext, useEffect, useState } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import queryString from 'querystring'
import { loadStripe } from '@stripe/stripe-js'
import config from 'publicconfig'
import Cookies from 'js-cookie'
import { Home } from './pages/home'
import { BlogIndex } from './pages/blog/index'

import './app.css'
import { ApolloClient, ApolloProvider } from '@apollo/client'
import { BlogSingle } from './pages/blog/single'
import { Helmet } from 'react-helmet'
import { LiveAnnouncer } from 'react-aria-live'
import { Error404 } from './pages/error/404'
import { StartPage } from './pages/start'
import { RequestPage } from './pages/request'
import { PrivacyPolicy } from './pages/legal/privacy'
import { TermsAndConditions } from './pages/legal/terms'
import { StripeContext } from './contexts/stripe'
import { ThankYou } from './pages/thankyou'
import { GlobalPromotionsProvider } from './globalpromotions'
import { UserTypeContext } from './contexts/usertype'
import { AppStateContext } from './contexts/appstate'
import { UserType } from './components/elements/alerts/usertype'
import { ContentForWebsite } from './pages/home/variations/contentforwebsite'
import { SeoContentWriters } from './pages/home/variations/seocontentwriters'
import { Signup } from './pages/signup/signup'

const STRIPE_PUBLIC_KEY = config.stripe.publicKey

interface Props {
  client: ApolloClient<any>
}

const BlogIndexOrPreview = () => {
  const location = useLocation()
  const qs = queryString.parse(location.search.replace(/^\?/, ''))

  if (qs.p) {
    return <BlogSingle />
  } else {
    return <BlogIndex />
  }
}

const GOOGLE_TAG_MANAGER = `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-P2W8FJ5');

dataLayer.push({ event: 'recoreo.pageview' });
`

let stripePromise: ReturnType<typeof loadStripe>

const App: React.FC<Props> = ({ client }) => {
  const { initialUserType } = useContext(AppStateContext)
  const [userType, _setUserType] = useState<UserType | null>(initialUserType)

  useEffect(() => {
    window.dataLayer.push({
      event: 'recoreo.setUserType',
      recoreo: { userType },
    })
  }, [userType])

  const setUserType = (type: UserType) => {
    Cookies.set('rut', type, { expires: 365 })
    _setUserType(type)
  }

  return (
    <ApolloProvider client={client}>
      <StripeContext.Provider
        value={{
          loadStripe: () => {
            if (stripePromise) {
              return stripePromise
            }

            stripePromise = loadStripe(STRIPE_PUBLIC_KEY)

            return stripePromise
          },
        }}
      >
        <LiveAnnouncer>
          <Helmet>
            <script>{GOOGLE_TAG_MANAGER}</script>
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href="/apple-touch-icon.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="32x32"
              href="/favicon-32x32.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href="/favicon-16x16.png"
            />
            <link rel="manifest" href="/site.webmanifest" />
            <link
              rel="mask-icon"
              href="/safari-pinned-tab.svg"
              color="#5bbad5"
            />
            <meta name="msapplication-TileColor" content="#00819e" />
            <meta name="theme-color" content="#f20415" />
            <meta
              name="robots"
              content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
            />
          </Helmet>
          <UserTypeContext.Provider value={{ userType, setUserType }}>
            <GlobalPromotionsProvider>
              <Switch>
                <Route exact={true} path="/" component={Home} />
                <Route
                  exact={true}
                  path="/content-for-website"
                  component={ContentForWebsite}
                />
                <Route
                  exact={true}
                  path="/seo-content-writers"
                  component={SeoContentWriters}
                />
                <Route exact={true} path="/signup" component={Signup} />
                <Route
                  exact={true}
                  path="/blog/"
                  component={BlogIndexOrPreview}
                />
                <Route
                  exact={true}
                  path="/blog/:year/:month/:slug"
                  component={BlogSingle}
                />
                <Route exact={true} path="/start" component={StartPage} />
                <Route exact={true} path="/request" component={RequestPage} />
                <Route exact={true} path="/thank-you" component={ThankYou} />
                <Route
                  exact={true}
                  path="/legal/privacy-policy"
                  component={PrivacyPolicy}
                />
                <Route
                  exact={true}
                  path="/legal/terms"
                  component={TermsAndConditions}
                />
                <Route component={Error404} />
              </Switch>
            </GlobalPromotionsProvider>
          </UserTypeContext.Provider>
        </LiveAnnouncer>
      </StripeContext.Provider>
    </ApolloProvider>
  )
}

export default App
