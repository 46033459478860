import React, { useContext, useEffect, useReducer, useRef } from 'react'
import { hydrate } from 'react-dom'
import { Router } from 'react-router-dom'
import fetch from 'cross-fetch'
import { createBrowserHistory } from 'history'
import { wrapHistory } from 'oaf-react-router'
import * as patchBlockFn from 'history-block-async'

import App from './app'
import { AppStateContext } from './contexts/appstate'

import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'

const client = new ApolloClient({
  link: new HttpLink({ uri: `https://${window.location.hostname}/api`, fetch }),
  cache: new InMemoryCache().restore((window as any).__APOLLOSTATE__),
})

const history = patchBlockFn(createBrowserHistory())
history.block((_1: any, method: any, callback: any) => {
  if (method !== 'PUSH') {
    return true
  }

  // Hack to ensure that any tracking callback handlers attached to links run before we change the
  // pathname.
  setTimeout(() => {
    callback(true)
  }, 0)
})
wrapHistory(history, {
  primaryFocusTarget: 'body',
  announcePageNavigation: false,
})

hydrate(
  <AppStateContext.Provider value={(window as any).__APPSTATE__}>
    <Router history={history}>
      <App client={client} />
    </Router>
  </AppStateContext.Provider>,
  document.getElementById('root'),
)

if (module.hot) {
  module.hot.accept()
}
