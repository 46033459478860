import React from 'react'
import { Footer } from '../../components/compounds/footer'
import { SiteHeader } from '../../components/compounds/header'
import { PageTitle } from '../../components/elements/title'

const PrivacyPolicyContent = () => (
  <>
    <h1>Privacy Policy</h1>
    <h2>1. Introduction</h2>
    <p>
      <strong>1.1.</strong> We are committed to safeguarding the privacy of our
      website visitors; in this policy we explain how we will treat your
      personal information.
    </p>
    <p>
      <strong>1.2.</strong> We will ask you to consent to our use of cookies in
      accordance with the terms of this policy when you first visit our website.
    </p>
    <h2>2. Collecting personal information</h2>
    <p>
      <strong>2.1.</strong> We may collect, store and use the following kinds of
      personal information:{' '}
    </p>
    <ol type="a">
      <li>
        information about your computer and about your visits to and use of this
        website (including your IP address, geographical location, browser type
        and version, operating system, referral source, length of visit, page
        views and website navigation paths);
      </li>
      <li>
        information that you provide to us when registering with our website
        (including your email address);
      </li>
      <li>
        information that you provide when completing your profile or completing
        an order form on our website (including your name, email address,
        billing address, debit/credit card details)
      </li>
      <li>
        information that you provide to us for the purpose of subscribing to our
        email notifications and/or newsletters (including your name and email
        address);
      </li>
      <li>
        information contained in or relating to any communication that you send
        to us or send through our website (including the communication content
        and metadata associated with the communication); and
      </li>
      <li>any other personal information that you choose to send to us.</li>
    </ol>
    <p>
      <strong>2.2.</strong> Before you disclose to us the personal information
      of another person, you must obtain that person's consent to both the
      disclosure and the processing of that personal information in accordance
      with this policy.
    </p>
    <h2>3. Using personal information</h2>
    <p>
      <strong>3.1.</strong> Personal information submitted to us through our
      website will be used for the purposes specified in this policy or on the
      relevant pages of the website.
    </p>
    <p>
      <strong>3.2.</strong> We may use your personal information to:{' '}
    </p>
    <ol type="a">
      <li>administer our website and business;</li>
      <li>personalise our website for you;</li>
      <li>enable your use of the services available on our website;</li>
      <li>supply to you services purchased through our website;</li>
      <li>
        send statements, invoices and payment reminders to you, and collect
        payments from you;
      </li>
      <li>send you non-marketing commercial communications;</li>
      <li>
        send you email notifications that you have specifically requested;
      </li>
      <li>
        send you our email newsletter, if you have requested it (you can inform
        us at any time if you no longer require the newsletter);
      </li>
      <li>
        send you marketing communications relating to our business which we
        think may be of interest to you by email or similar technology (you can
        inform us at any time if you no longer require marketing
        communications);
      </li>
      <li>
        provide third parties with statistical information about our users (but
        those third parties will not be able to identify any individual user
        from that information);
      </li>
      <li>
        deal with enquiries and complaints made by or about you relating to our
        website;
      </li>
      <li>keep our website secure and prevent fraud; and</li>
      <li>
        process or analyze in any way that is necessary for us to fulfil a legal
        obligation.
      </li>
    </ol>
    <p>
      <strong>3.3.</strong> We will not, without your express consent, supply
      your personal information to any third party for the purpose of their or
      any other third party's direct marketing.
    </p>
    <p>
      <strong>3.4.</strong> All our website financial transactions are handled
      through our payment services provider Stripe, Inc. You can review the
      provider's privacy policy at{' '}
      <a href="https://stripe.com/en-gb/privacy">
        https://stripe.com/en-gb/privacy
      </a>
      . We will share information with our payment services provider only to the
      extent necessary for the purposes of processing payments you make via our
      website, refunding such payments and dealing with complaints and queries
      relating to such payments and refunds.
    </p>
    <h2>4. Disclosing personal information</h2>
    <p>
      <strong>4.1.</strong> We may disclose your personal information to any of
      our employees, officers, insurers, professional advisers, agents,
      suppliers or subcontractors insofar as reasonably necessary for the
      purposes set out in this policy.
    </p>
    <p>
      <strong>4.2.</strong> We may disclose your personal information to any
      member of our group of companies (this means our subsidiaries, our
      ultimate holding company and all its subsidiaries) insofar as reasonably
      necessary for the purposes set out in this policy.
    </p>
    <p>
      <strong>4.3.</strong> We may disclose your personal information:
    </p>
    <ol type="a">
      <li>to the extent that we are required to do so by law;</li>
      <li>in connection with any ongoing or prospective legal proceedings;</li>
      <li>
        in order to establish, exercise or defend our legal rights (including
        providing information to others for the purposes of fraud prevention and
        reducing credit risk);
      </li>
      <li>
        to the purchaser (or prospective purchaser) of any business or asset
        that we are (or are contemplating) selling; and
      </li>
      <li>
        to any person who we reasonably believe may apply to a court or other
        competent authority for disclosure of that personal information where,
        in our reasonable opinion, such court or authority would be reasonably
        likely to order disclosure of that personal information.
      </li>
    </ol>
    <p>
      <strong>4.3.</strong> Except as provided in this policy, we will not
      provide your personal information to third parties.
    </p>
    <h2>5. International data transfers</h2>
    <p>
      <strong>5.1.</strong> Information that we collect may be stored and
      processed in and transferred between any of the countries in which we
      operate in order to enable us to use the information in accordance with
      this policy.
    </p>
    <p>
      <strong>5.2.</strong> Information that we collect may be transferred to
      the following countries which do not have data protection laws equivalent
      to those in force in the European Economic Area: the United States of
      America.
    </p>
    <p>
      <strong>5.3.</strong> You expressly agree to the transfers of personal
      information described in this Section 5.
    </p>
    <h2>6. Retaining personal information</h2>
    <p>
      <strong>6.1.</strong> This Section 6 sets out our data retention policies
      and procedure, which are designed to help ensure that we comply with our
      legal obligations in relation to the retention and deletion of personal
      information.
    </p>
    <p>
      <strong>6.2.</strong> Personal information that we process for any purpose
      or purposes shall not be kept for longer than is necessary for that
      purpose or those purposes.
    </p>
    <p>
      <strong>6.3.</strong> Notwithstanding the other provisions of this Section
      6, we will retain documents (including electronic documents) containing
      personal data:
    </p>
    <ol type="a">
      <li>to the extent that we are required to do so by law;</li>
      <li>
        if we believe that the documents may be relevant to any ongoing or
        prospective legal proceedings; and
      </li>
      <li>
        in order to establish, exercise or defend our legal rights (including
        providing information to others for the purposes of fraud prevention and
        reducing credit risk).
      </li>
    </ol>
    <h2>7. Security of personal information</h2>
    <p>
      <strong>7.1.</strong> We will take reasonable technical and organisational
      precautions to prevent the loss, misuse or alteration of your personal
      information.
    </p>
    <p>
      <strong>7.2.</strong> You are responsible for keeping the password you use
      for accessing our website confidential; we will not ask you for your
      password (except when you log in to our website).
    </p>
    <h2>8. Amendments</h2>
    <p>
      <strong>8.1.</strong> We may update this policy from time to time by
      publishing a new version on our website.
    </p>
    <p>
      <strong>8.2.</strong> You should check this page occasionally to ensure
      you are happy with any changes to this policy.
    </p>
    <p>
      <strong>8.3.</strong> We may notify you of changes to this policy by
      email.
    </p>
    <h2>9. Your rights</h2>
    <p>
      <strong>9.1.</strong> You may instruct us to provide you with any personal
      information we hold about you; provision of such information will be
      subject to the supply of appropriate evidence of your identity (for this
      purpose, we will usually accept a photocopy of your passport certified by
      a solicitor or bank plus an original copy of a utility bill showing your
      current address).
    </p>
    <p>
      <strong>9.2.</strong> We may withhold personal information that you
      request to the extent permitted by law.
    </p>
    <p>
      <strong>9.3.</strong> You may instruct us at any time not to process your
      personal information for marketing purposes.
    </p>
    <p>
      <strong>9.4.</strong> In practice, you will usually either expressly agree
      in advance to our use of your personal information for marketing purposes,
      or we will provide you with an opportunity to opt out of the use of your
      personal information for marketing purposes.
    </p>
    <h2>10. Third party websites</h2>
    <p>
      <strong>10.1.</strong> Our website includes hyperlinks to, and details of,
      third party websites.
    </p>
    <p>
      <strong>10.2.</strong> We have no control over, and are not responsible
      for, the privacy policies and practices of third parties.
    </p>
    <h2>11. Updating information</h2>
    <p>
      <strong>11.1.</strong> Please let us know if the personal information that
      we hold about you needs to be corrected or updated.
    </p>
    <h2>12. About cookies</h2>
    <p>
      <strong>12.1.</strong> A cookie is a file containing an identifier (a
      string of letters and numbers) that is sent by a web server to a web
      browser and is stored by the browser. The identifier is then sent back to
      the server each time the browser requests a page from the server.
    </p>
    <p>
      <strong>12.2.</strong> Cookies may be either "persistent" cookies or
      "session" cookies: a persistent cookie will be stored by a web browser and
      will remain valid until its set expiry date, unless deleted by the user
      before the expiry date; a session cookie, on the other hand, will expire
      at the end of the user session, when the web browser is closed.{' '}
    </p>
    <p>
      <strong>12.3.</strong> Cookies do not typically contain any information
      that personally identifies a user, but personal information that we store
      about you may be linked to the information stored in and obtained from
      cookies.
    </p>
    <p>
      <strong>12.4.</strong> Cookies can be used by web servers to identify and
      track users as they navigate different pages on a website and identify
      users returning to a website.
    </p>
    <h2>13. Our cookies</h2>
    <p>
      <strong>13.1. </strong> We use both session and persistent cookies on our
      website.
    </p>
    <p>
      <strong>13.2.</strong> The names of the cookies that we use on our
      website, and the purposes for which they are used, are set out below:{' '}
    </p>
    <table>
      <thead>
        <tr>
          <th scope="col">Cookie name</th>
          <th scope="col">Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>_gat_UA-74263044-7</td>
          <td>
            Used by Google Analytics to rate limit requests and detect bots.
          </td>
        </tr>
        <tr>
          <td>_gid</td>
          <td>Used by Google Analytics to identify and distinguish users.</td>
        </tr>
        <tr>
          <td>_ga</td>
          <td>Used by Google Analytics to identify and distinguish users.</td>
        </tr>
      </tbody>
    </table>
    <h2>14. Data protection registration</h2>
    <p>
      <strong>14.1.</strong> We are registered as a data controller with the UK
      Information Commissioner's Office.
    </p>
    <p>
      <strong>14.2.</strong> Our data protection registration number is ZA170042
    </p>
    <h2>15. Our details</h2>
    <p>
      <strong>15.1.</strong> This website is owned and operated by Recoreo Ltd.
    </p>
    <p>
      <strong>15.2.</strong> We are registered in England and Wales under
      registration number 10005255, and our registered office is at Recoreo Ltd,
      20-22 Wenlock Road, London, England, N1 7GU.
    </p>
  </>
)

export const PrivacyPolicy = () => {
  return (
    <>
      <SiteHeader />
      <PageTitle>Privacy Policy - Recoreo</PageTitle>
      <div className="relative py-16 bg-white overflow-hidden">
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="mt-6 prose prose-lg md:prose-xl mx-auto">
            <PrivacyPolicyContent />
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
