import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import { useHydrating } from '../../hooks/hydrating'
import { LiveMessage } from 'react-aria-live'

export const PageTitle: React.FC = ({ children }) => {
  const { pathname } = useLocation()
  const hydrating = useHydrating()

  useEffect(() => {
    if (hydrating) {
      return
    }

    window.dataLayer.push({ event: 'recoreo.pageview' })
  }, [pathname])

  return (
    <>
      <Helmet defer={false}>
        <title>{children}</title>
      </Helmet>
      <LiveMessage message={`Navigated to ${children}`} aria-live="polite" />
    </>
  )
}
