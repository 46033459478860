import React from 'react'
import { Nav } from '../hero/nav'

interface Props {
  funnel?: boolean
}

export const SiteHeader: React.FC<Props> = ({ funnel = false }) => (
  <div className="relative bg-white">
    <div className="relative z-40 pt-6 pb-6 sm:pb-6">
      <Nav funnel={funnel} />
    </div>
  </div>
)
