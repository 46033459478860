import React, { useContext, useState } from 'react'
import { add, parseISO, isWeekend, getDay } from 'date-fns'
import { AppStateContext } from '../contexts/appstate'

export const DELIVERY_WORKING_DAYS = 1
const DELIVERY_DAY_LABELS = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
}

export const useDeliveryDay = () => {
  const { serverDate } = useContext(AppStateContext)
  let deliveryDate = parseISO(serverDate)
  let daysAdded = 0

  while (daysAdded < DELIVERY_WORKING_DAYS) {
    deliveryDate = add(deliveryDate, { days: 1 })

    const skipDay = isWeekend(deliveryDate)

    if (!skipDay) {
      daysAdded++
    }
  }

  return daysAdded === 1
    ? 'tomorrow'
    : DELIVERY_DAY_LABELS[getDay(deliveryDate)]
}
