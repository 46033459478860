import { Transition } from '@headlessui/react'
import React, { useEffect, useState } from 'react'
import { Icon } from '../icon'

interface Props {
  title: string
  content: React.ReactElement
  onConfirm: Function
}

export const HelpAlert: React.FC<Props> = ({ title, content, onConfirm }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [leaving, setLeaving] = useState(false)

  useEffect(() => {
    setIsOpen(true)
  }, [])

  useEffect(() => {
    // Hack to wait for the animation
    if (leaving) {
      setTimeout(() => {
        onConfirm()
      }, 300)
    }
  }, [leaving])

  const onClick = () => {
    setLeaving(true)
  }

  return (
    <div
      className="fixed z-50 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <Transition
          show={isOpen && !leaving}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="fixed inset-0 bg-c-background-bg bg-opacity-75 transition-opacity"
            aria-hidden="true"
            onClick={onClick}
          ></div>
        </Transition>
        {/* This element is to trick the browser into centering the modal contents. */}
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div
          className={`ease-out ${
            isOpen && !leaving
              ? 'opacity-100 translate-y-0 sm:scale-100'
              : 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
          } duration-300 inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full sm:p-6`}
        >
          <div className="block absolute top-0 right-0 pt-4 pr-4">
            <button
              type="button"
              className="bg-white rounded-md text-c-close hover:bg-c-secondary-bg-hover hover:text-c-close-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-c-focus-ring"
              onClick={onClick}
            >
              <span className="sr-only">Close</span>
              <Icon type="x" width={6} height={6} />
            </button>
          </div>
          <div>
            <div className="mt-0 text-left">
              <h3
                className="text-lg leading-6 font-medium text-c-heading-text"
                id="modal-title"
              >
                {title}
              </h3>
              <div className="mt-2">{content}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
